import Utils from '@/utils/Utils';
import UtilsRequest from '@/utils/UtilsRequest';

const API_URL = 'FornDeterm/';

class FornitoriService {
    getAnniFornDetermine(enteId, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("enteId", enteId);
        
        UtilsRequest.get(API_URL + "GetAnniFornDetermine", parametri, 
        successFn,
        failFn
        );
    }

    getFornDetermine(anno, enteId, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("anno", anno);
        parametri.append("enteId", enteId);
        UtilsRequest.get(API_URL + "GetFornDetermine", parametri, 
        successFn,
        failFn
        );
    }

    getFornDetermineImpegni(anno, enteId, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("anno", anno);
        parametri.append("enteId", enteId);
        UtilsRequest.get(API_URL + "GetFornDetermineImpegni", parametri, 
        successFn,
        failFn
        );
    }

    getFornDetermina(id, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("id", id);
        UtilsRequest.get(API_URL + "GetFornDetermina", parametri, 
        successFn,
        failFn
        );
    }

    getFornDeterminaImpegno(idImpegno, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("idImpegno", idImpegno);
        UtilsRequest.get(API_URL + "GetFornDeterminaImpegno", parametri, 
        successFn,
        failFn
        );
    }

    getFornBudgetEnte(enteId, anno, tipoForn, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("enteId", enteId);
        parametri.append("anno", anno);
        parametri.append("tipoForn", tipoForn);
        UtilsRequest.get(API_URL + "GetFornBudgetEnte", parametri, 
        successFn,
        failFn
        );
    }

    getTemplateFornDetermina(successFn, failFn){
        UtilsRequest.get(API_URL + "GetTemplateFornDetermina", null, 
        successFn,
        failFn
        );
    }

    getTemplateImpegno(successFn, failFn){
        UtilsRequest.get(API_URL + "GetTemplateImpegno", null, 
        successFn,
        failFn
        );
    }

    getTemplateManodopera(successFn, failFn){
        UtilsRequest.get(API_URL + "GetTemplateManodopera", null, 
        successFn,
        failFn
        );
    }

    getFornitori(successFn, failFn){
        UtilsRequest.get(API_URL + "GetFornitori", null, 
        successFn,
        failFn
        );
    }

    createFornDetermina(fornDetermina, successFn, failFn){
        UtilsRequest.post(API_URL + "CreateFornDetermina", fornDetermina, 
        successFn,
        failFn
        );
    }

    updateFornDetermina(fornDetermina, successFn, failFn){
        UtilsRequest.put(API_URL + "UpdateFornDetermina", fornDetermina, 
        successFn,
        failFn
        );
    }

    deleteFornDetermina(id, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("id", id);
        UtilsRequest.delete(API_URL + "DeleteFornDetermina", parametri, 
        successFn,
        failFn
        );
    }    
}

export default new FornitoriService();