<template>
  <v-card flat>
    <v-card-title
      class="black--text"
      style="margin-bottom: 20px; margin-top: 20px"
      >{{ $t("interventi") }}
    </v-card-title>
    <v-row>
      <v-col >
        <v-data-table
          style="max-height:200px; overflow:auto"
          id="tblInterventi"
          dense
          hide-default-footer
          :headers="headersInterventi"
          :items="eInterventi"
          :value="[interventoSel]"
          @click:row="onTableRowInterventoClick"
          single-select
          item-key="guid"
          class="elevation-1"
          loading-text="Caricamento dati in corso..."
          :items-per-page="-1"
        >
          <template v-slot:top>
            <v-dialog v-model="dialogDeleteIntervento" max-width="500px">
              <v-card>
                <v-card-title class="text-h5"
                  >Sei sicuro di voler cancellare l'elemento
                  selezionato?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="closeDeleteIntervento"
                    >Annulla</v-btn
                  >
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="deleteItemConfirmIntervento"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              v-show="!shouldDisable"
              small
              @click="deleteItemIntervento(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card-title
          class="black--text"
          style="margin-bottom: 20px; margin-top: 20px"
          >{{ $t("datiIntervento") }}
        </v-card-title>
      </v-col>
      <v-col align="end">
        <v-btn 
            tile 
            color="darkred"
            :disabled="shouldDisable"
          @click="onNuovoInterventoClick"
        >
          <v-icon left> mdi-plus-circle-outline </v-icon>
          {{ $t("nuovo") }}
        </v-btn>
      </v-col>
    </v-row>
    <template v-if="interventoSel != null">
    <v-row>
      <v-col>
        <v-text-field
          v-model="interventoSel.nNumero"
          :label="$t('buoni.numeroObbl')"
          type="number"
          min="1"
          max="1000000"
          :rules="[rules.valueMin(interventoSel.nNumero, 0), rules.valueMax(interventoSel.nNumero, 1000000)]"
          :disabled="shouldDisable"
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-select
          :items="t_interventi"
          return-object
          :label="$t('scadenze.tipoIntervento')"
          item-text="cDescrizione"
          item-value="id"
          :disabled="shouldDisable"
          v-model="interventoSel.interventoTipoDTO"
          max-height="100"
        >
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-textarea
          v-model="interventoSel.xLavoroRichiesto"
          outlined
          :label="$t('interventoRichiesto')"
          :readonly="shouldDisable"
        >
        </v-textarea>
      </v-col>
      <v-col>
        <v-textarea
          v-model="interventoSel.xLavoroEseguito"
          outlined
          :label="$t('lavoroEseguito')"
          :readonly="shouldDisable"
        >
        </v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="interventoSel.nQuantita"
          :label="$t('buoni.quantita')"
          type="number"
          step="0.01"
          min="1"
          max="999999"
          :rules="[rules.valueMin(interventoSel.nQuantita, 0), rules.valueMax(interventoSel.nQuantita, 1000000)]"
          :disabled="shouldDisable"
          v-on:blur="changeImponibile"
          @keydown="onKeyDownNegative"
        >
        </v-text-field>
      </v-col>
      <v-col>
          <v-currency-field 
            :label="$t('buoni.prezzoUnitario')"
            prefix="€"
            v-model="interventoSel.nPrezzo"
            :disabled="shouldDisable"
            @change="changeImponibile"/>
      </v-col>
      <v-col>
          <v-currency-field 
            :label="$t('buoni.imponibileIntervento')"
            prefix="€"
            v-model="interventoSel.nImporto"
            disabled
            @change="changeImportoTotale"/>
      </v-col>
      <v-col> </v-col>
    </v-row>

    <v-divider class="mx-4"></v-divider>
    </template>
    <v-row>
      <v-col style="margin-top: 10px" align="end">
        <v-btn 
          tile 
          color="darkred" 
          :disabled="shouldDisable"
          @click="onInterventiFornitoreClick"
          >
          <v-icon left> mdi-file-chart </v-icon>
          {{ $t("interventi") }}
        </v-btn>
        <v-btn
          tile
          color="darkred"
          :disabled="shouldDisabledInterv"
          style="margin-left: 10px; margin-rigth: 20px; "
          @click="onSaveInterventoClick"
        >
          <v-icon left> mdi-content-save </v-icon>
          {{ $t("buoni.salvaIntervento") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from 'vue-property-decorator';

import { environment } from "@/environment/environment";
import Utils from "@/utils/Utils";

import BuoniService from "@/service/BuoniService";

@Component({
  props: {
    t_interventi: {
      type: Array,
      required: true,
    },
    eInterventi: [],
    shouldDisable: false,
    shouldDisabledInterv: false,
  },
})
export default class CompInterventi extends Vue {
  // Variabili
  guidIntervToDelete = null;
  intervTempl = null;
  dialogDeleteIntervento = false;
  interventoSel = [];

  rules = Utils.rules;

  headersInterventi = [
    {
      text: "Numero",
      align: "start",
      value: "nNumero",
    },
    { text: "Tipo intervento", value: "interventoTipoDTO.cDescrizione" },
    { text: "Intervento richiesto", value: "xLavoroRichiesto" },
    {
      text: "Data cancellazione",
      value: "hDel",
      align: " d-none",
      filter: this.hDeInterventoFilter,
    },
    { text: "Azioni", value: "actions", sortable: false },
  ];

  @Watch('eInterventi')
  onAzzeraSelezione(){
    this.interventoSel = Object.assign({}, this.intervTempl);
  }
  
  created() {
    this.getTemplateIntervento();
    this.interventoSel = Object.assign({}, this.intervTempl);
  }

  onTableRowInterventoClick(intervento, row)
  {
      this.interventoSel = intervento;
      if (intervento.id == 0)
      {
        this.shouldDisabledInterv = true;
        return;
      }
      if (!this.shouldDisable) this.shouldDisabledInterv = false;
  }

  onSaveInterventoClick() {
    // Verifiche preliminare prima del caricamento
    if (this.intervTempl == null) {
      // KO
      // Invio il messaggio al padre
      this.$emit(
        "send-messagge",
        "error",
        "erroreCaricamentoTemplateIntervento"
      );
      return;
    }
    if (
      Utils.isNullOrUndefined(this.interventoSel.nNumero) ||
      this.interventoSel.nNumero == "" ||
      this.interventoSel.interventoTipoDTO.id <= 0 
    ) {
      // KO
      // Invio il messaggio al padre
      this.$emit("send-messagge", "error", "buoni.erroreAggiuntaIntervento");
      return;
    }
    
    if (
      parseFloat(this.interventoSel.nNumero) <= 0 
    ) {
      this.$emit("send-messagge", "error", "buoni.erroreValoriIntervento");
      return;
    }

    // Invio l'oggetto al padre
    this.$emit("load-intervento", this.interventoSel);

    // Svuoto i campi dopo l'inserimento in griglia
    this.onNuovoInterventoClick();
  }

  onNuovoInterventoClick() {
    this.interventoSel = Object.assign({}, this.intervTempl);
    this.interventoSel.nNumero = '';
    this.interventoSel.guid = Utils.createGuid();
    this.shouldDisabledInterv = false;

    //this.$refs.form.resetValidation();
  }

  onInterventiFornitoreClick() {
    if (!Utils.isNullOrUndefined( this.interventoSel.interventoTipoDTO.id) && this.interventoSel.interventoTipoDTO.id > 0)
    {
      var url = environment.urlSrc + "Buoni/GeneraListaInterventiManutenzione";
      window.location =
        url + "?id_buono=" + this.interventoSel.eBuoniId + "&tipoIntervento=" + this.interventoSel.interventoTipoDTO.id;
    }
    else
    {
      this.$emit("send-messagge", "error", "erroreTipoIntPerReport");
    }
  }

  onKeyDownNegative(key) {
    if (key.key == "-")
    {
      key.preventDefault();
    }
    else
      return true;
  }

  changeImponibile() {
    let quantita = Utils.isNullOrUndefinedOrEmpty(this.interventoSel.nQuantita)
      ? 0
      : this.interventoSel.nQuantita;
    let prezzo = Utils.isNullOrUndefinedOrEmpty(this.interventoSel.nPrezzo)
      ? 0
      : this.interventoSel.nPrezzo;

    this.interventoSel.nImporto =
      parseFloat(quantita) * parseFloat(prezzo) ;
    this.interventoSel.nImporto = this.interventoSel.nImporto.toFixed(2);
  }

  deleteItemIntervento(item) {
    this.guidIntervToDelete = item.guid;
    this.dialogDeleteIntervento = true;
  }

  closeDeleteIntervento() {
    this.dialogDeleteIntervento = false;
  }

  deleteItemConfirmIntervento() {
    this.$emit("delete-intervento", this.guidIntervToDelete);

    this.dialogDeleteIntervento = false;
    // Svuoto i campi dopo l'inserimento in griglia
    this.onNuovoInterventoClick();
  }

  hDeInterventoFilter(value) {
    // Necessario per filtrare la griglia dei documenti e non visualizzare i doc cancellati
    return value === null;
  }

  getTemplateIntervento() {
    var self = this;
    BuoniService.getTemplateIntervento(
      (resp) => {
        console.log("getTemplateIntervento");
        console.log(resp);
        self.intervTempl = resp;
        self.intervTempl.nNumero = '';
        self.interventoSel = Object.assign({}, self.intervTempl);
        self.interventoSel.nNumero = '';
        self.interventoSel.interventoTipoDTO = [];
      },
      (err) => {
        console.log("Errore getTemplateIntervento");
        console.log(err);
        return null;
      }
    );
  }
}
</script>