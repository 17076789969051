import UtilsRequest from '@/utils/UtilsRequest';

const API_URL = 'Buoni/';

class BuoniService {
    getTemplateBuono(successFn, failFn){
        UtilsRequest.get(API_URL + "GetTemplateBuono", null,
        successFn,
        failFn
        );
    }

    getAnniBuoni(enteId, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("enteId", enteId);
        UtilsRequest.get(API_URL + "GetAnniBuoni", parametri, 
        successFn,
        failFn
        );
    }

    getBuoni(anno, enteId, tipoBuoni, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("anno", anno);
        parametri.append("enteId", enteId);
        parametri.append("tipoBuoni", tipoBuoni);
        UtilsRequest.get(API_URL + "GetBuoni", parametri, 
        successFn,
        failFn
        );
    }

    getBuono(id, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("id", id);
        UtilsRequest.get(API_URL + "GetBuono", parametri, 
        successFn,
        failFn
        );
    }

    generaBuono(id_buono, tipo_stampa, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("id_buono", id_buono);
        parametri.append("tipo_stampa", tipo_stampa);
        UtilsRequest.get(API_URL + "GeneraBuono", parametri, 
        successFn,
        failFn,
        undefined, "arraybuffer"
        );
    }

    getDefaultEmailBuonoDTO(id, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("idBuono", id);
        UtilsRequest.get(API_URL + "GetDefaultEmailBuonoDTO", parametri, 
        successFn,
        failFn
        );
    }

    createBuono(buono, successFn, failFn){
        UtilsRequest.post(API_URL + "CreateBuono", buono, 
        successFn,
        failFn
        );
    }

    updateBuono(buono, successFn, failFn){
        UtilsRequest.put(API_URL + "UpdateBuono", buono, 
        successFn,
        failFn
        );
    }

    deleteBuono(id, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("id", id);
        UtilsRequest.delete(API_URL + "DeleteBuono", parametri, 
        successFn,
        failFn
        );
    }

    /// INTERVENTI
    getTemplateIntervento(successFn, failFn){
        UtilsRequest.get(API_URL + "GetTemplateIntervento", null,
        successFn,
        failFn
        );
    }
/*
    emettiBuono(buono, successFn, failFn){
        UtilsRequest.put(API_URL + "EmettiBuono", buono, 
        successFn,
        failFn,
        undefined, "arraybuffer", "application/octet-stream"
        );
    }
*/
    emettiBuono(buono, successFn, failFn){
        UtilsRequest.put(API_URL + "EmettiBuono", buono, 
        successFn,
        failFn
        );
    }

    chiudiBuono(buono, successFn, failFn){
        UtilsRequest.put(API_URL + "ChiudiBuono", buono, 
        successFn,
        failFn
        );
    }
}

export default new BuoniService();
