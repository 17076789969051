import Utils from '@/utils/Utils';
import UtilsRequest from '@/utils/UtilsRequest';

const API_URL = 'Enti/';

class EntiService {
    getEnti(successFn, failFn){
        UtilsRequest.get(API_URL + "GetEnti", null,
        successFn,
        failFn
        );
    }

    getEnte(id, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("id", id);

        UtilsRequest.get(API_URL + "GetEnte", parametri,
        successFn,
        failFn
        );
    }

    getEnteAndLinkObjects(id, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("id", id);

        UtilsRequest.get(API_URL + "GetEnteAndLinkObjects", parametri,
        successFn,
        failFn
        );
    }

    
    updateEnte(ente, successFn, failFn){
        UtilsRequest.put(API_URL + "UpdateEnte", ente, 
        successFn,
        failFn
        );
    }
    
}

export default new EntiService();