import UtilsRequest from '@/utils/UtilsRequest';

const API_URL = 'User/';

class UtentiService {
    getUsers(storico, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("storico", storico);

        UtilsRequest.get(API_URL + "GetUsers", parametri,
        successFn,
        failFn
        );
    }

    getUsersEnti(enteId, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("storico", false);
        parametri.append("enteId", enteId);

        UtilsRequest.get(API_URL + "GetUsers", parametri,
        successFn,
        failFn
        );
    }

    getUserEntiByRuolo(idUser, idRuolo, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("idUser", idUser);
        parametri.append("idRole", idRuolo);

        UtilsRequest.get(API_URL + "GetUserEntiByRuolo", parametri,
        successFn,
        failFn
        );
    }

    getUser(id, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("id", id);
        UtilsRequest.get(API_URL + "GetUser", parametri,
        successFn,
        failFn
        );
    }

    getTemplateUser(successFn, failFn){
        UtilsRequest.get(API_URL + "GetTemplateUser", null,
        successFn,
        failFn
        );
    }

    getUsersServizio(idServ, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("idServizio", idServ);
        UtilsRequest.get(API_URL + "GetUsersServizio", parametri,
        successFn,
        failFn
        );
    }

    createUser(user, successFn, failFn){
        UtilsRequest.post(API_URL + "CreateUser", user, 
        successFn,
        failFn
        );
    }

    updateUser(user, successFn, failFn){
        UtilsRequest.put(API_URL + "UpdateUser", user, 
        successFn,
        failFn
        );
    }

    updatePassword(user, successFn, failFn){
        UtilsRequest.put(API_URL + "UpdatePassword", user, 
        successFn,
        failFn
        );
    }
    
    resetPassword(user, successFn, failFn){
        UtilsRequest.put(API_URL + "ResetPassword", user, 
        successFn,
        failFn
        );
    }

    recuperaPassword(email, successFn, failFn){
        UtilsRequest.put(API_URL + "RecuperaPassword", email, 
        successFn,
        failFn
        );
    }

    deleteUtente(id, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("id", id);
        UtilsRequest.delete(API_URL + "DeleteUser", parametri, 
        successFn,
        failFn
        );
    }
}

export default new UtentiService();
