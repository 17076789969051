import UtilsRequest from '@/utils/UtilsRequest';

const API_URL = 'Veicoli/';

class VeicoliService {
    getTemplateVeicolo(successFn, failFn){
        UtilsRequest.get(API_URL + "GetTemplateVeicolo", null,
        successFn,
        failFn
        );
    }

    getVeicoli(enteId, storico, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("enteId", enteId);
        parametri.append("storico", storico);
        UtilsRequest.get(API_URL + "GetVeicoli", parametri, 
        successFn,
        failFn
        );
    }

    getVeicoliBuoni(enteId, storico, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("enteId", enteId);
        parametri.append("storico", storico);
        UtilsRequest.get(API_URL + "GetVeicoliBuoni", parametri, 
        successFn,
        failFn
        );
    }

    getVeicoliInSharing(enteId, sharing, storico, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("enteId", enteId);
        parametri.append("sharing", sharing);
        parametri.append("storico", storico);
        UtilsRequest.get(API_URL + "GetVeicoliInSharing", parametri, 
        successFn,
        failFn
        );
    }

    getVeicolo(id, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("id", id);
        UtilsRequest.get(API_URL + "GetVeicolo", parametri, 
        successFn,
        failFn
        );
    }

    createVeicolo(veicolo, successFn, failFn){
        UtilsRequest.post(API_URL + "CreateVeicolo", veicolo, 
        successFn,
        failFn
        );
    }

    updateVeicolo(veicolo, successFn, failFn){
        UtilsRequest.put(API_URL + "UpdateVeicolo", veicolo, 
        successFn,
        failFn
        );
    }

    deleteVeicolo(id, acquirente, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("id", id);
        parametri.append("acquirente", acquirente);
        UtilsRequest.delete(API_URL + "DeleteVeicolo", parametri, 
        successFn,
        failFn
        );
    }
}

export default new VeicoliService();
