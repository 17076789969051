import Utils from '@/utils/Utils';
import UtilsRequest from '@/utils/UtilsRequest';

const API_URL = 'Tipologiche/';
const paramsNull = null;

class TipologicheService {
    
    getElencoTipologiche(successFn, failFn){
        UtilsRequest.get(API_URL + "GetElencoTipologiche", paramsNull, 
        successFn,
        failFn
        );
    }
    
    getAllTipologica(codTip, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("codTip", codTip);

        UtilsRequest.get(API_URL + "GetAllTipologica", parametri, 
        successFn,
        failFn
        );
    }

    getTipologica(codTip, idTipologica, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("codTipologica", codTip);
        parametri.append("idTipologica", idTipologica);

        UtilsRequest.get(API_URL + "GetTipologica", parametri, 
        successFn,
        failFn
        );
    }

    getTemplateTipologica(successFn, failFn){
        const parametri = new URLSearchParams();

        UtilsRequest.get(API_URL + "GetTemplateTipologica", null,
        successFn,
        failFn
        );
    }

    createTipologica(tipDTO, successFn, failFn){
        UtilsRequest.post(API_URL + "CreateTipologica", tipDTO, 
        successFn,
        failFn
        );
    }

    updateTipologica(tipDTO, successFn, failFn){
        UtilsRequest.put(API_URL + "UpdateTipologica", tipDTO, 
        successFn,
        failFn
        );
    }

    deleteTipologica(codTipologica, id, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("codTipologica", codTipologica);
        parametri.append("idTipologica", id);
        
        UtilsRequest.delete(API_URL + "DeleteTipologica", parametri, 
        successFn,
        failFn
        );
    }

    // ******************
    // FORNITORI
    // ******************
    getTFornCategoria(successFn, failFn){
        UtilsRequest.get(API_URL + "GetTFornCategoria", paramsNull, 
        successFn,
        failFn
        );
    }

    getTVeicoloDestUso(successFn, failFn){
        UtilsRequest.get(API_URL + "GetTVeicoloDestUso", paramsNull, 
        successFn,
        failFn
        );
    }

    getTDocumentoFornDetermineAll(successFn, failFn){
        UtilsRequest.get(API_URL + "GetTDocumentoFornDetermineAll", paramsNull, 
        successFn,
        failFn
        );
    }

    // ******************
    // VEICOLI
    // ******************
    getTVeicoloUso(successFn, failFn){
        UtilsRequest.get(API_URL + "GetTVeicoloUso", paramsNull, 
        successFn,
        failFn
        );
    }

    getTVeicoloTipTras(successFn, failFn){
        UtilsRequest.get(API_URL + "GetTVeicoloTipTras", paramsNull, 
        successFn,
        failFn
        );
    }

    getTVeicoloTipSharing(successFn, failFn){
        UtilsRequest.get(API_URL + "GetTVeicoloTipSharing", paramsNull, 
        successFn,
        failFn
        );
    }

    getTVeicoloTipoEmis(successFn, failFn){
        UtilsRequest.get(API_URL + "GetTVeicoloTipoEmis", paramsNull, 
        successFn,
        failFn
        );
    }

    getTVeicoloClasseAmb(successFn, failFn){
        UtilsRequest.get(API_URL + "GetTVeicoloClasseAmb", paramsNull, 
        successFn,
        failFn
        );
    }

    getTVeicoloCategoria(successFn, failFn){
        UtilsRequest.get(API_URL + "GetTVeicoloCategoria", paramsNull, 
        successFn,
        failFn
        );
    }

    getTDocumentoVeicoloAll(successFn, failFn){
        UtilsRequest.get(API_URL + "GetTDocumentoVeicoloAll", paramsNull, 
        successFn,
        failFn
        );
    }

    getTVeicoloCarbur(successFn, failFn){
        UtilsRequest.get(API_URL + "GetTVeicoloCarbur", paramsNull, 
        successFn,
        failFn
        );
    }

    // ******************
    // SCADENZE
    // ******************
    getTScadenzaTipo(successFn, failFn){
        UtilsRequest.get(API_URL + "GetTScadenzaTipo", paramsNull, 
        successFn,
        failFn
        );
    }

    getTScadenzaStato(successFn, failFn){
        UtilsRequest.get(API_URL + "GetTScadenzaStato", paramsNull, 
        successFn,
        failFn
        );
    }

    getTScadenzaConteggio(successFn, failFn){
        UtilsRequest.get(API_URL + "GetTScadenzaConteggio", paramsNull, 
        successFn,
        failFn
        );
    }

    getTScadenzaCategoria(successFn, failFn){
        UtilsRequest.get(API_URL + "GetTScadenzaCategoria", paramsNull, 
        successFn,
        failFn
        );
    }

    getTInterventoTipo(successFn, failFn){
        UtilsRequest.get(API_URL + "GetTInterventoTipo", paramsNull, 
        successFn,
        failFn
        );
    }

    // ******************
    // ATTREZZATURE
    // ******************
    getTAttrezFunz(successFn, failFn){
        UtilsRequest.get(API_URL + "GetTAttrezFunz", paramsNull, 
        successFn,
        failFn
        );
    }

    getTDocumentoAttrezzaturaAll(successFn, failFn){
        UtilsRequest.get(API_URL + "GetTDocumentoAttrezzaturaAll", paramsNull, 
        successFn,
        failFn
        );
    }
    
    // ******************
    // BUONI
    // ******************
    getTDocumentoBuoniAll(successFn, failFn){
        UtilsRequest.get(API_URL + "GetTDocumentoBuoniAll", paramsNull, 
        successFn,
        failFn
        );
    }

    getTBuoniCausali(successFn, failFn){
        UtilsRequest.get(API_URL + "GetTBuoniCausali", paramsNull, 
        successFn,
        failFn
        );
    }

    getTBuoniStato(successFn, failFn){
        UtilsRequest.get(API_URL + "GetTBuoniStato", paramsNull, 
        successFn,
        failFn
        );
    }

    getTBuoniTipo(successFn, failFn){
        UtilsRequest.get(API_URL + "GetTBuoniTipo", paramsNull, 
        successFn,
        failFn
        );
    }

    getTRuoli(successFn, failFn){
        UtilsRequest.get(API_URL + "GetPRoles", paramsNull, 
        successFn,
        failFn
        );
    }
}

export default new TipologicheService();