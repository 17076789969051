/**
 * UtilsProject class
 */
import Utils from '@/utils/Utils';
import moment from 'moment';

class UtilsDate {

	PATTERN_DATE = "DD-MM-YYYY";
    PATTERN_DATE_TIME = "DD-MM-YYYY HH:mm:ss.SSS";

	FORMAT_DATE = "YYYY-MM-DD";
    FORMAT_DATE_TIME = "YYYY-MM-DD HH:mm:ss";

    getNowDate(formatDate){
        if(Utils.isNullOrUndefined(formatDate)){
            formatDate = this.FORMAT_DATE;
        }

        return moment().format(formatDate);
    }

    formatDate (date) {
        if (Utils.isNullOrUndefined(date)){
            return null;
        }

        const [year, month, day] = date.split('-');
        return `${day}/${month}/${year}`; //TODO metodo generale in base a lingua
        //return `${month}/${day}/${year}`
    }

    getYearFromDate (date) {
        if (Utils.isNullOrUndefined(date)){
            return null;
        }

        const [year, month, day] = date.split('-');
        return `${year}`; //TODO metodo generale in base a lingua
        //return `${month}/${day}/${year}`
    }

    formatDateWithTime (date) {
        if (Utils.isNullOrUndefined(date)){
            return null;
        }

        let [year, month, day, time] = date.split('-');
        [day, time] = day.split(" ");
        time = time.split(".")[0]; //remove millisecond
        return `${day}/${month}/${year} ${time}`; //TODO metodo generale in base a lingua
        //return `${month}/${day}/${year}`
    }
    
	convertStringToDate(dateString, pattern){
        if(Utils.isNullOrUndefined(pattern)){
            //pattern = this.PATTERN_DATE;
            pattern = this.FORMAT_DATE;
        }

        let date = moment(dateString, pattern);
        return date.format(this.FORMAT_DATE);
	}

    convertStringToDateIta(dateString, pattern){
        if(Utils.isNullOrUndefined(pattern)){
            pattern = this.PATTERN_DATE;
        }
        let date = moment(dateString, pattern);
        return date.format(this.PATTERN_DATE);
	}

	convertStringToDateWithTime(dateString, pattern){
        if(Utils.isNullOrUndefined(pattern)){
            pattern = this.PATTERN_DATE_TIME;
        }

        let date = moment(dateString, pattern);
        return date.format(this.FORMAT_DATE_TIME);
	}

    isGreaterThen(data1, data2)
    {
        var dateA = moment(data1, "YYYY-MM-DD"); // replace format by your one
        var dateB = moment(data2, "YYYY-MM-DD");
        
        if (dateA.diff(dateB) > 0) {
             return true;
        } else {
            return false;
        }        
    }
}

export default new UtilsDate();