<template>
  <v-card flat>
    <v-row>
      <v-col>
        <v-select
          :items="t_documenti"
          v-model="docComboSelected"
          return-object
          item-text="cDescrizione"
          item-value="id"
          :disabled="shouldDisable"
          :label="$t('tipoDocumento')"
        >
        </v-select>
      </v-col>
      <v-col>
        <v-file-input
          ref="fileInput"
          accept="All files/*"
          :label="$t('caricaDocumento')"
          :disabled="shouldDisable"
          
          v-model="fileSel"
          @change="getFileName"
        ></v-file-input>
      </v-col>
      <v-col>
        <v-btn
          tile
          color="darkred"
          :disabled="shouldDisable"
          @click="onCaricaDocumentoClick"
        >
          <v-icon left> mdi-upload </v-icon>
          {{ $t("carica") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-divider class="mx-4"></v-divider>
    <v-card-title
      class="black--text"
      style="margin-bottom: 20px; margin-top: 20px"
    >
      {{ $t("documentiCaricati") }}
    </v-card-title>
    <v-row>
      <v-col>
        <v-data-table
          dense
          hide-default-footer
          :headers="headersDocumenti"
          :items="eDocumenti"
          :value="[docSelected]"
          single-select
          item-key="guid"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-dialog v-model="dialogDeleteDoc" max-width="500px">
              <v-card>
                <v-card-title class="text-h5"
                  >{{ $t("confermaCancellazione") }} </v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDeleteDocument"
                    >Annulla</v-btn
                  >
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="deleteItemConfirmDocument"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              v-show="!shouldDisable"
              small
              @click="deleteItemDocument(item)"
            >
              mdi-delete
            </v-icon>
            <v-icon 
               
              small 
              @click="downloadDocument(item)">
              mdi-download
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import Vue from "vue";
import Component from "vue-class-component";
import { environment } from "@/environment/environment";
import Utils from "@/utils/Utils";
import UtilsDate from "@/utils/UtilsDate";
import UtilsRequest from '@/utils/UtilsRequest';

import DocumentiService from "@/service/DocumentiService";

@Component({
  props: {
    t_documenti: {
      type: Array,
      required: true,
    },
    eDocumenti: [],
    shouldDisable: false,
  },
})
export default class CompDocuments extends Vue {
  // Variabili
  fileSel = null;
  fileSel2 = [];
  fileName = "";
  guidDocToDelete = null;
  documentoTempl = null;
  dialogDeleteDoc = false;
  docSelected = null;
  docComboSelected = null;

  rules = Utils.rules;

  headersDocumenti = [
    {
      text: "Tipo documento",
      align: "start",
      value: "tDocumento.cDescrizione",
    },
    { text: "Nome documento", value: "cNomeFile" },
    { text: "Data caricamento", value: "hIns" },
    {
      text: "Data cancellazione",
      value: "hDel",
      align: " d-none",
      filter: this.hDelDocumentFilter,
    },
    { text: "Azioni", value: "actions", sortable: false },
  ];
 
  created() {
    this.getTemplateDocumento();
  }
  
  onCaricaDocumentoClick() {
    // Verifiche preliminare prima del caricamento
    if (this.documentoTempl == null) {
      // KO
      // Invio il messaggio al padre
      this.$emit("send-messagge", "error", "erroreCaricamentoTemplateDocumento");
      return;
    }
    if (this.fileSel2.length == 0 || this.docComboSelected == null) {
      // KO
      // Invio il messaggio al padre
      this.$emit("send-messagge", "error", "erroreCaricamentoDocumentoInput");
      return;
    }

    var docTemp = Object.assign({}, this.documentoTempl);
    docTemp.tDocumento = this.docComboSelected;
    docTemp.oFile = this.fileSel2;
    docTemp.cNomeFile = this.fileName;
    //docTemp.objectId = this.fornitoreSel.id;
    let date = UtilsDate.getNowDate();
    docTemp.hIns = date;
    docTemp.guid = Utils.createGuid();

    // Invio l'oggetto al padre
    this.$emit("load-document", docTemp);

    // Azzero le variabili dell'oggetto caricato
    this.fileSel = null;
    this.fileSel2 = [];
  }

  downloadDocument(item) {
    if (item.id == 0)
    {
      this.$emit("send-messagge", "error", "erroreDownloadDocNonSalvato");
      return;
    }
    //var url = environment.urlSrc + "Documenti/GetDocumentoFile";
    //window.location = url + "?id=" + item.id;
    DocumentiService.getDocumentoFile(
      item.id,
      function(data, resp) {
          const blob = new Blob([data]);
          const el = document.createElement("a");
          el.href = window.URL.createObjectURL(blob);
          const regex = /filename=(?<filename>[^,;]+);/g;
          const match = regex.exec(resp.headers['content-disposition']);
          let filename = ""; // or any other value you consider default
          if (typeof match !== 'undefined' && match !== null) {
              filename = match.groups['filename'];
          }
          el.download = filename;
          el.click();
        },
        (fail) => {
          alert(fail);
        },
      )
   }

  getFileName(e) {
    /*
    var valid = this.$refs.fileInput.validate();
    if (!valid)
    {
      if (e != null) 
      {
        if (e.size == 0)
          this.$refs.fileInput.reset();
      }
      else
        this.$refs.fileInput.reset();
      return;
    }
    */

    if (e != null) {
      if (e.size > 10000000)
      {
        // KO
        this.fileName = null;
        this.fileSel = null;
        this.fileSel2 = null;
        this.$refs.fileInput.reset();
        // Invio il messaggio al padre
        this.$emit("send-messagge", "error", "erroreCaricamentoDocumentoInputSize");
        return;
      }
      else
      {
        this.fileName = e.name;

        var file = e,
          read = new FileReader();
        const fileByteArray = [];
        read.readAsArrayBuffer(file);
        read.onloadend = (evt) => {
          if (evt.target.readyState === FileReader.DONE) {
            const arrayBuffer = evt.target.result,
              array = new Uint8Array(arrayBuffer);
            for (const a of array) {
              fileByteArray.push(a);
            }
            console.log(fileByteArray);
            this.fileSel2 = fileByteArray;
          }
        };
      }
    }
  }

  deleteItemDocument(item) {
    this.guidDocToDelete = item.guid;
    this.dialogDeleteDoc = true;
  }

  closeDeleteDocument() {
    this.dialogDeleteDoc = false;
  }

  deleteItemConfirmDocument() {
    this.$emit("delete-document", this.guidDocToDelete);
    this.dialogDeleteDoc = false;
  }

    hDelDocumentFilter(value) {
    // Necessario per filtrare la griglia dei documenti e non visualizzare i doc cancellati
    return value === null;
  }

  getTemplateDocumento() {
    var self = this;

    DocumentiService.getTemplateDocumento(
      (resp) => {
        console.log("GetTemplateDocumento");
        console.log(resp);

        self.documentoTempl = resp;
      },
      (err) => {
        console.log("Errore GetTemplateDocumento");
        console.log(err);
        return null;
      }
    );

  }
}
</script>
