import Utils from '@/utils/Utils';
import UtilsRequest from '@/utils/UtilsRequest';

const API_URL = 'Excel/';

class ExcelService {
    generaExcelAttrezzature(enteId, storico, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("enteId", enteId);
        parametri.append("storico", storico);

        UtilsRequest.get(API_URL + "GeneraExcelAttrezzature", parametri, 
            successFn,
            failFn,
        undefined, "arraybuffer");
    }

    generaExcelVeicoli(enteId, storico, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("enteId", enteId);
        parametri.append("storico", storico);

        UtilsRequest.get(API_URL + "GeneraExcelVeicoli", parametri, 
            successFn,
            failFn,
        undefined, "arraybuffer");
    }

    generaExcelScadenze(successFn, failFn){
        UtilsRequest.get(API_URL + "GeneraExcelScadenze", null, 
            successFn,
            failFn,
        undefined, "arraybuffer");
    }

    generaExcelBuoni(anno, enteId, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("anno", anno);
        parametri.append("enteId", enteId);

        UtilsRequest.get(API_URL + "GeneraExcelBuoni", parametri, 
            successFn,
            failFn,
        undefined, "arraybuffer");
    }

    generaExcelRifornimenti(enteId, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("enteId", enteId);

        UtilsRequest.get(API_URL + "GeneraExcelRifornimenti", parametri, 
            successFn,
            failFn,
        undefined, "arraybuffer");
    }

    generaExcelFornitori(anno, enteId, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("anno", anno);
        parametri.append("enteId", enteId);

        UtilsRequest.get(API_URL + "GeneraExcelFornitori", parametri, 
            successFn,
            failFn,
        undefined, "arraybuffer");
    }

    generaExcelBuoniAperti(buonoId, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("id_buono", buonoId);

        UtilsRequest.get(API_URL + "GeneraExcelBuoniAperti", parametri, 
            successFn,
            failFn,
        undefined, "arraybuffer");
    }

    generaExcelSegnalazioniRiferimenti(riferimentoId, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("id_rifornimento", riferimentoId);

        UtilsRequest.get(API_URL + "GeneraExcelSegnalazioniRiforn", parametri, 
            successFn,
            failFn,
        undefined, "arraybuffer");
    }

    generaExcelPrenotazioni(enteId, storico, dataDal, dataAl, successFn, failFn) {
        const parametri = new URLSearchParams();
        parametri.append("enteId", enteId);
        parametri.append("storico", storico);
        parametri.append("dataDal", dataDal);
        parametri.append("dataAl", dataAl);
        UtilsRequest.get(API_URL + "GeneraExcelPrenotazioni", parametri, 
            successFn,
            failFn,
        undefined, "arraybuffer");
    }

    generaExcelNotifiche(userId, successFn, failFn) {
        const parametri = new URLSearchParams();
        parametri.append("userId", userId);
        UtilsRequest.get(API_URL + "GeneraExcelNotifiche", parametri, 
            successFn,
            failFn,
        undefined, "arraybuffer");
    }

}

export default new ExcelService();