<template>
  <v-form ref="form" class="mx-2" method="post" enctype="multipart/form-data" onkeydown="return event.key != 'Enter';">
    <v-container id="BUONI" fluid tag="section">
      <v-row>
        <v-col :cols="isEditEmissioneBuoni ? '4' : '12'">
          <base-material-card
            color="white"
            icon="mdi-cash-multiple"
            iconcolor="darkred"
            :title="$t('emissionebuoni')"
            class="px-5 py-3"
            backgroundcolor=""
            titlecolor="red"
          >
            <v-container v-if="isCreatedCorrectly" v-resize="onContainerChange">
              <v-row>
                <v-col cols="2">
                  <v-card-title>
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      :label="$t('buoni.ricerca')"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-card-title>
                </v-col>

                <v-col cols="2">
                  <v-select
                    :items="t_anni"
                    :label="$t('anno')"
                    style="margin-top: 10px; width: 150px"
                    v-model="defaultAnno"
                    @change="onAnnoChange"
                  >
                  </v-select>
                </v-col>

                <v-col cols="8" align="end">
                  <v-btn
                    v-show="isNuovoVeicEnabled"
                    color="darkred"
                    width="180px"
                    @click="onNuovoBuonoVeicolo"
                  >
                    <v-icon left> mdi-plus-circle-outline </v-icon>
                    {{ $t("buoni.nuovoVeicolo") }}
                  </v-btn>
                  <v-btn
                    v-show="isNuovaAttrezEnabled"
                    color="darkred"
                    width="180px"
                    @click="onNuovoBuonoAttrezzatura"
                  >
                    <v-icon left> mdi-plus-circle-outline </v-icon>
                    {{ $t("buoni.nuovoAttrez") }}
                  </v-btn>
                  <v-btn 
                    color="darkred" 
                    width="180px"
                    @click="onExportGrid">
                    <v-icon left> mdi-export </v-icon>
                    {{ $t("esporta") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
            <v-data-table
              style="max-height:600px; overflow:auto"
              id="tblMainBuoni"
              :items="buoni.source"
              :headers="headersColumns"
              :search="search"
              :value="[buoni.selected]"
              single-select
              :items-per-page="-1"
              @click:row="onTableRowClick"
              :loading="isloading_buoni"
              class="elevation-1"
              loading-text="Caricamento dati in corso..."
            >
              <template v-slot:[`item.nImportoTotale`]="{ item }">
                <span>{{ '€' + item.nImportoTotale.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</span>
              </template>
            </v-data-table>
          </base-material-card>
        </v-col>
        <v-col v-if="isEditEmissioneBuoni" cols="8">
          <base-material-card
            color="white"
            iconcolor="darkred"
            icon="mdi-cash"
            :title="$t('page.buono.titolo')"
            class="px-5 py-3"
          >
            <v-tabs v-model="tab" background-color="transparent" grow>
              <v-tab
                v-for="item in buoniTabs"
                :key="item"
                @change="getTabSelected(item)"
              >
                {{ item }}
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item v-for="item in buoniTabs" :key="item" eager>
                <v-card v-if="item == 'Testa'" flat>
                  <v-row dense>
                    <v-col>
                      <v-select
                        :items="eEnti"
                        return-object
                        :label="$t('ente')"
                        item-text="cDescrizione"
                        item-value="id"
                        v-model="buoni.selected.enteDTO"
                        :rules="[rules.selectRequired]"
                        :disabled="enteShouldDisable"
                        @change="onEnteChanged($event)"
                      >
                      </v-select>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="buoni.selected.dataBuonoText"
                        :label="$t('dataObbl')"
                        type="date"
                        min="1990-01-01"
                        max="2050-12-31"
                        :disabled="shouldDisable"
                        :rules="[rules.required]"
                        @change="onDataBuonoChanged($event)"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="buoni.selected.cNumero"
                        :label="$t('numeroBuono')"
                        disabled
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-select
                        :items="t_causali"
                        :label="$t('buoni.causaleObbl')"
                        item-text="cDescrizione"
                        item-value="id"
                        return-object
                        :rules="[rules.selectRequired]"
                        v-model="buoni.selected.buonoCausaleDTO"
                        :disabled="shouldDisable"
                      >
                      </v-select>
                    </v-col>
                    <v-col>
                      <v-select
                        :items="t_statobuono"
                        return-object
                        :label="$t('statoBuono')"
                        item-text="cDescrizione"
                        item-value="id"
                        :disabled="true"
                        v-model="buoni.selected.buonoStatoDTO"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="buoni.selected.tipologiaBuono == 'Veicolo'"
                    dense
                  >
                    <v-col>
                      <v-select
                        :items="eVeicoli"
                        :label="$t('veicoloObbl')"
                        item-text="selezioneVeicolo"
                        item-value="id"
                        return-object
                        v-model="buoni.selected.veicoloDTO"
                        :rules="[rules.selectRequired]"
                        :disabled="shouldDisable"
                        @change="onVeicoloChanged($event)"
                      >
                      </v-select>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="buoni.selected.nLetturaKm"
                        :label="$t('buoni.letturaKm')"
                        type="number"
                        min="1"
                        max="999999"
                        :rules="[rules.valueMin(buoni.selected.nLetturaKm, 0), rules.valueMax(buoni.selected.nLetturaKm, 999999)]"
                        :disabled="shouldDisable"
                        @keydown="onKeyDown"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="buoni.selected.tipologiaBuono == 'Attrezzatura'"
                    dense
                  >
                    <v-col>
                      <v-select
                        :items="eAttrezzature"
                        :label="$t('attrezzaturaObbl')"
                        item-text="cDescrizione"
                        item-value="id"
                        return-object
                        v-model="buoni.selected.attrezDTO"
                        :rules="[rules.selectRequired]"
                        :disabled="shouldDisable"
                        @change="onAttrezzaturaChanged($event)"
                      >
                      </v-select>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="buoni.selected.nOreLettura"
                        :label="$t('buoni.letturaOre')"
                        type="number"
                        min="1"
                        max="999999"
                        :rules="[rules.valueMin(buoni.selected.nOreLettura, 0), rules.valueMax(buoni.selected.nOreLettura, 9999)]"
                        :disabled="shouldDisable"
                        @keydown="onKeyDown"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col>
                      <v-select
                        :items="eFornitoriBudget"
                        :label="$t('fornitoreImpegnoObbl')"
                        item-text="selezioneImpegno"
                        item-value="id"
                        return-object
                        v-model="buoni.selected.fornBudgetDTO"
                        :rules="[rules.selectRequired]"
                        :disabled="shouldDisable"
                        @change="onFornitoreChanged($event)"
                      >
                      </v-select>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col>
                      <v-card-title class="blue--text" style="font-size: 14px"
                        >{{ $t("budget") }}
                      </v-card-title>
                      <v-card-title class="black--text" style="font-size: 12px">
                        {{ '€' + buoni.selected.fornBudgetDTO.nTotale.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
                      </v-card-title>
                    </v-col>
                    <v-col>
                      <v-card-title class="blue--text" style="font-size: 14px"
                        >{{ $t("spesa") }}
                      </v-card-title>
                      <v-card-title class="black--text" style="font-size: 12px">
                        {{ '€' + buoni.selected.fornBudgetDTO.nSpesa.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
                      </v-card-title>
                    </v-col>
                    <v-col>
                      <v-card-title class="blue--text" style="font-size: 14px"
                        >{{ $t("residuo") }}
                      </v-card-title>
                      <v-card-title class="black--text" style="font-size: 12px">
                        {{ '€' + buoni.selected.fornBudgetDTO.nResiduo.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
                      </v-card-title>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-card-title class="blue--text" style="font-size: 14px"
                        >{{ $t("giorni") }}
                      </v-card-title>
                      <v-card-title class="black--text" style="font-size: 12px">
                        {{ buoni.selected.fornBudgetDTO.nGiorniMancanti }}
                      </v-card-title>
                    </v-col>
                    <v-col>
                      <v-card-title class="blue--text" style="font-size: 14px"
                        >{{ $t("spesaMediaMensile") }}
                      </v-card-title>
                      <v-card-title class="black--text" style="font-size: 12px">
                        {{ '€' + buoni.selected.fornBudgetDTO.nSpesaMediaMensile.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
                      </v-card-title>
                    </v-col>
                    <v-col>
                      <v-card-title class="blue--text" style="font-size: 14px"
                        >{{ $t("scostamento") }}
                      </v-card-title>
                      <v-card-title class="black--text" style="font-size: 12px">
                        {{ '€' + buoni.selected.fornBudgetDTO.nScostamento.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
                      </v-card-title>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col :disabled="shouldDisable">
                      <v-card-title
                        class="black--text"
                        style="margin-bottom: 20px; margin-top: 20px"
                        >{{ $t("scadenze") }}
                      </v-card-title>
                      <v-data-table
                        dense
                        hide-default-footer
                        :headers="headersScadenze"
                        :items="eScadenze"
                        v-model="selectedScad"
                        class="elevation-1"
                      >
                        <template v-slot:[`item.isBuonoSel`]="{ item }">
                          <v-checkbox
                            v-model="item.isBuonoSel"
                            dense
                            :disabled="shouldDisable"
                            :ripple="false"
                            @change="onScadenzaClick(item, $event)"
                          ></v-checkbox>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-card-title
                        class="black--text"
                        style="margin-bottom: 20px; margin-top: 20px"
                        >{{ $t("importoBuono") }}
                      </v-card-title>
                    </v-col>
                    <v-col>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col>
                      <v-select
                        :items="t_buonotipo1"
                        return-object
                        :label="$t('tipo1')"
                        item-text="cDescrizione"
                        item-value="id"
                        :disabled="shouldDisable"
                        v-model="buoni.selected.buonoTipo1DTO"
                      >
                      </v-select>
                    </v-col>
                    <v-col>
                      <v-currency-field 
                        :label="$t('euro')"
                        prefix="€"
                        v-model="buoni.selected.nImporto1"
                        :disabled="shouldDisable"
                        @change="changeImportoTotale"/>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="buoni.selected.dataFattura1Text"
                        :label="$t('data')"
                        type="date"
                        min="1990-01-01"
                        max="2050-12-31"
                        :disabled="shouldDisable"
                      >
                      </v-text-field>
                    </v-col>

                    <v-col>
                      <v-text-field
                        v-model="buoni.selected.cNumFattura1"
                        :label="$t('numero')"
                        maxlength="20"
                        :disabled="shouldDisable"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col>
                      <v-select
                        :items="t_buonotipo2"
                        return-object
                        :label="$t('tipo2')"
                        item-text="cDescrizione"
                        item-value="id"
                        :disabled="shouldDisable"
                        v-model="buoni.selected.buonoTipo2DTO"
                      >
                      </v-select>
                    </v-col>
                    <v-col>
                      <v-currency-field 
                        :label="$t('euro')"
                        prefix="€"
                        v-model="buoni.selected.nImporto2"
                        :disabled="shouldDisable"
                        @change="changeImportoTotale"/>

                    </v-col>

                    <v-col>
                      <v-text-field
                        v-model="buoni.selected.dataFattura2Text"
                        :label="$t('data')"
                        type="date"
                        min="1990-01-01"
                        max="2050-12-31"
                        :disabled="shouldDisable"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="buoni.selected.cNumFattura2"
                        :label="$t('numero')"
                        maxlength="20"
                        :disabled="shouldDisable"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col>
                      <v-select
                        :items="t_buonotipo3"
                        return-object
                        :label="$t('tipo3')"
                        item-text="cDescrizione"
                        item-value="id"
                        :disabled="shouldDisable"
                        v-model="buoni.selected.buonoTipo3DTO"
                      >
                      </v-select>
                    </v-col>
                    <v-col>
                      <v-currency-field 
                        :label="$t('euro')"
                        prefix="€"
                        v-model="buoni.selected.nImporto3"
                        :disabled="shouldDisable"
                        @change="changeImportoTotale"/>

                    </v-col>

                    <v-col>
                      <v-text-field
                        v-model="buoni.selected.dataFattura3Text"
                        :label="$t('data')"
                        type="date"
                        min="1990-01-01"
                        max="2050-12-31"
                        :disabled="shouldDisable"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="buoni.selected.cNumFattura3"
                        :label="$t('numero')"
                        maxlength="20"
                        :disabled="shouldDisable"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col> </v-col>
                    <v-col>
                      <v-currency-field 
                        :label="$t('importoTotale')"
                        prefix="€"
                        v-model="buoni.selected.nImportoTotale"
                        disabled
                        />

                    </v-col>
                    <v-col> </v-col>
                    <v-col>
                      <v-currency-field 
                        :label="$t('buoni.importoInterventi')"
                        prefix="€"
                        v-model="buoni.selected.nImportoInterventiTotale"
                        disabled
                        />
                    </v-col>
                  </v-row>

                  <v-card-title
                    class="black--text"
                    style="margin-bottom: 20px; margin-top: 20px"
                    >{{ $t("dettaglio") }}
                  </v-card-title>
                  <v-row dense>
                    <v-col>
                      <v-text-field
                        v-model="buoni.selected.nAliquota"
                        :label="$t('aliquotaIva')"
                        type="number"
                        step="0.01"
                        :rules="[rules.valueMin(buoni.selected.nAliquota, 0), rules.valueMax(buoni.selected.nAliquota, 100)]"
                        :disabled="shouldDisable"
                        @change="changeImponibileRicambi($event)"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-currency-field 
                        :label="$t('importoGaranzia')"
                        prefix="€"
                        v-model="buoni.selected.nImpGaranzia"
                        :disabled="shouldDisable"
                        />
                    </v-col>
                    <v-col>
                      <v-checkbox
                        v-model="buoni.selected.flagSoloRicambi"
                        :label="$t('soloImponibileRicambi')"
                        hide-details
                        :disabled="shouldDisable"
                        @change="checkSoloImpRicambi($event)"
                      >
                      </v-checkbox>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col>
                      <v-currency-field 
                        :label="$t('imponibileManodopera')"
                        prefix="€"
                        v-model="buoni.selected.nImpManodopera"
                        :disabled="isManodopDisabled"
                        @change="changeOre($event)"
                        />
                    </v-col>
                    <v-col>
                      <v-currency-field 
                        :label="$t('prezzoUnitario')"
                        prefix="€"
                        v-model="buoni.selected.nPrezzoManodopera"
                        :disabled="isManodopDisabled"
                        @change="changeOre($event)"
                        />
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="buoni.selected.nOreManodopera"
                        :label="$t('ore')"
                        type="number"
                        disabled
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-currency-field 
                        :label="$t('imponibileRicambi')"
                        prefix="€"
                        v-model="buoni.selected.nImpRicambi"
                        disabled
                        />
                    </v-col>
                    <v-col>
                      <v-currency-field 
                        :label="$t('importoIva')"
                        prefix="€"
                        v-model="buoni.selected.nImportoIva"
                        disabled
                        />
                    </v-col>
                    <v-col> </v-col>
                  </v-row>

                  <v-divider class="mx-4"></v-divider>
                  <v-card-title
                    class="black--text"
                    style="margin-bottom: 20px; margin-top: 20px"
                    >{{ $t("datiStampa") }}
                  </v-card-title>
                  <v-row dense>
                    <v-col>
                      <v-text-field
                        v-model="buoni.selected.dataStampaText"
                        :label="$t('stampatoIl')"
                        type="date"
                        min="1990-01-01"
                        max="2050-12-31"
                        disabled
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="buoni.selected.stampatoDa"
                        :label="$t('da')"
                        disabled
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-select
                        :items="stampaPrezzi"
                        v-model="buoni.selected.stampaPrezzi"
                        item-value="code"
                        item-text="cDescrizione"
                        label="Stampa Prezzi"
                        :disabled="shouldDisable"
                      >
                      </v-select>
                    </v-col>
                    <v-col>
                      <v-btn
                        tile
                        color="darkred"
                        style="margin-left: 10px; margin-top: 10px"
                        :disabled="shouldDisable"
                        @click="onAnteprimaClick"
                      >
                        <v-icon left> mdi-printer-eye </v-icon>
                        {{ $t("anteprimaStampa") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-divider class="mx-4"></v-divider>
                  <v-row
                    style="
                      margin-top: 20px;
                      margin-left: 20px;
                      margin-bottom: 10px;
                    "
                  >
                    <v-col align="end">
                      <v-btn
                        tile
                        color="darkred"
                        style="margin-left: 10px"
                        @click="onBuoniApertiClick"
                      >
                        <v-icon left> mdi-file-chart </v-icon>
                        {{ $t("buoniAperti") }}
                      </v-btn>
                      <v-btn
                        tile
                        color="darkred"
                        style="margin-left: 10px"
                        @click="onBudgetClick"
                      >
                        <v-icon left> mdi-file-chart </v-icon>
                        {{ $t("budget") }}
                      </v-btn>
                      <v-btn
                        tile
                        color="darkred"
                        style="margin-left: 10px"
                        @click="onInterventoFornitoreClick"
                      >
                        <v-icon left> mdi-file-chart </v-icon>
                        {{ $t("interventiFornitore") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card>

                <CompInterventi
                  v-if="item == 'Interventi'"
                  :eInterventi="buoni.selected.interventi"
                  :t_interventi="t_interventi"
                  :shouldDisable="shouldDisable"
                  :shouldDisabledInterv="shouldDisable"
                  v-on:send-messagge="showAlertMessage"
                  v-on:load-intervento="loadIntervento"
                  v-on:delete-intervento="deleteIntervento"
                />

                <CompDocuments
                  v-if="item == 'Documenti'"
                  :eDocumenti="buoni.selected.documenti"
                  :t_documenti="t_documenti"
                  :shouldDisable="shouldDisable"
                  v-on:send-messagge="showAlertMessage"
                  v-on:load-document="loadDocument"
                  v-on:delete-document="deleteDocument"
                />
              </v-tab-item>
            </v-tabs-items>

            <v-divider class="mx-4"></v-divider>
            <v-row
              style="margin-top: 20px; margin-left: 20px; margin-bottom: 10px"
            >
              <v-alert
                id="alertDettaglio"
                shaped
                :value="isAlertDetailVisible"
                :type="alertType"
                dismissible
              >
                {{ messaggioAlert }}
              </v-alert>
            </v-row>
            <v-row
              style="margin-top: 20px; margin-left: 20px; margin-bottom: 10px"
            >
              <v-btn
                v-show="!isBuonoStorico && buoni.selected.hDel == null"
                tile
                color="darkred"
                v-if="!isHidden"
                v-on:click="isHidden = true"
                @click="onEditClick"
              >
                <v-icon left> mdi-pencil </v-icon>
                {{ $t("modifica") }}
              </v-btn>
              <v-btn
                v-show="!isBuonoStorico  && buoni.selected.hDel == null"
                tile
                color="darkred"
                v-if="isHidden"
                @click="onSaveClick"
              >
                <v-icon left> mdi-content-save </v-icon>
                {{ $t("salva") }}
              </v-btn>
              <v-btn
                v-show="buoni.selected.buonoStatoDTO.cDescrizione == 'Emesso'  && buoni.selected.hDel == null"
                tile
                color="darkred"
                @click="onChiudiBuonoClick"
              >
                <v-icon left> mdi-content-save </v-icon>
                {{ $t("chiudiBuono") }}
              </v-btn>
              <v-btn
                v-show="buoni.selected.buonoStatoDTO.cDescrizione == 'Emesso'  && buoni.selected.hDel == null"
                tile
                color="darkred"
                @click="onMettiInBozzaClick"
              >
                <v-icon left> mdi-content-save </v-icon>
                {{ $t("annullaEmissione") }}
              </v-btn>
              <v-btn
                v-show="buoni.selected.buonoStatoDTO.cDescrizione == 'Bozza' && buoni.selected.hDel == null && buoni.selected.id > 0"
                tile
                color="darkred"
                @click="onDisattivaClick"
              >
                <v-icon left> mdi-delete </v-icon>
                {{ $t("disattiva") }}
              </v-btn>
              <!-- Emetti Buono-->
              <v-dialog
                v-if="objEmissioneBuono != null"
                v-model="dialogEmettiBuono"
                persistent
                max-width="600px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-show="isEmettiPossible && buoni.selected.hDel == null"
                    tile
                    color="darkred"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon left> mdi-baseball-diamond-outline </v-icon>
                    {{ $t("buoni.emettiBuono") }}
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h4; justify-left">{{
                      $t("buoni.emettiBuono")
                    }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row dense>
                        <v-col cols="12" sm="6" md="6">
                          <v-select
                            :items="['Mail', 'Stampa']"
                            v-model="objModalInvio"
                            label="Modalità invio"
                            required
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-checkbox
                            style="margin-top: 20px"
                            label="Stampa nota"
                            dense
                            v-model="objEmissioneBuono.flagNote"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="6">
                          <v-card-text
                            >Veicolo in manutenzione dopo l'emissione del buono?
                          </v-card-text>
                        </v-col>
                        <v-radio-group v-model="radioGroup">
                          <v-radio label="Si" value="1"></v-radio>
                          <v-radio label="No" value="2"></v-radio>
                        </v-radio-group>
                        <v-card width="550px" dense>
                          <v-col cols="12" dense>
                            <v-card-title>
                              <span class="text-h5"> Email</span>
                            </v-card-title>
                            <v-col cols="12">
                              <v-text-field
                                label="A"
                                required
                                dense
                                v-model="objEmissioneBuono.cDest"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                              <v-text-field
                                label="CC"
                                required
                                dense
                                v-model="objEmissioneBuono.cCc"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                              <v-text-field
                                label="Oggetto"
                                required
                                dense
                                v-model="objEmissioneBuono.cOggetto"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                              <v-textarea
                                label="Testo"
                                required
                                outlined
                                v-model="objEmissioneBuono.xTesto"
                                name="txtTesto"
                              >
                              </v-textarea>
                            </v-col>
                          </v-col>
                        </v-card>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="dialogEmettiBuono = false"
                    >
                      Chiudi
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="onEmettiClick">
                      Emetti
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- Disattiva Buono-->
              <v-dialog v-model="dialogDisattivaBuono" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >Sei sicuro di voler disattivare il buono selezionato?
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="closeDisattivaBuono"
                      >Annulla</v-btn
                    >
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="confirmDisattivaBuono"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- Chiudi Buono-->
              <v-dialog v-model="dialogChiudiBuono" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >Sei sicuro di voler chiudere il buono selezionato? 
                  </v-card-title>
                  <v-card-title class="text-h5"
                    >
                    Prima di chiudere il buono verificare che le scadenze da evadere siano state selezionate correttamente.
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="closeChiudiBuono"
                      >Annulla</v-btn
                    >
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="confirmChiudiBuono"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-btn tile color="darkred" @click="onCloseDetail">
                <v-icon left> mdi-door-open </v-icon>
                {{ $t("chiudi") }}
              </v-btn>
            </v-row>
          </base-material-card>
        </v-col>
      </v-row>
      <v-row>
        <v-alert
          id="alertGrid"
          shaped
          :value="isAlertGridVisible"
          :type="alertType"
          dismissible
        >
          {{ messaggioAlert }}
        </v-alert>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import Vue from "vue";
import Component from "vue-class-component";

import i18n from "@/i18n";
import { environment } from "@/environment/environment";

import Utils from "@/utils/Utils";
import UtilsDate from "@/utils/UtilsDate";

import BuoniService from "@/service/BuoniService";
import TipologicheService from "@/service/TipologicheService";
import EntiService from "@/service/EntiService";
import VeicoliService from "@/service/VeicoliService";
import AttrezzatureService from "@/service/AttrezzatureService";
import FornitoriService from "@/service/FornitoriService";
import UtentiService from "@/service/UtentiService";
import ExcelService from "@/service/ExcelService";

import CompDocuments from "@/components/CompDocuments";
import CompInterventi from "@/components/CompInterventi";


@Component({
  components: {
    CompDocuments,
    CompInterventi,
  },
})
export default class Buoni extends Vue {
  buoni = {
    select: [],
    selected: null,
    source: [],
  };

  rules = Utils.rules;

  buoniTabs = ["Testa", "Interventi", "Documenti"];

  headersColumnsRidotte = [
    {
      text: "Numero buono",
      align: "start",
      value: "cNumero",
    },
    { text: "Data buono", value: "dataBuonoText" },
    { text: "Veicolo", value: "veicoloDTO.selezioneVeicolo" },
    { text: "Attrezzatura", value: "attrezDTO.marcaModello" },
    { text: "Stato buono", value: "buonoStatoDTO.cDescrizione" },    
  ];

  sortBy = "dataBuonoText";
  sortDesc = true;

  headersEmissioneBuoniMainColumns = [
    {
      text: "Numero buono",
      align: "start",
      value: "cNumero",
    },
    { text: "Data buono", value: "dataBuonoText", width: "150px" },
    { text: "Tipologia", value: "tipologiaBuono" },
    { text: "Veicolo", value: "veicoloDTO.selezioneVeicolo" },
    { text: "Attrezzatura", value: "attrezDTO.marcaModello" },
    { text: "Fornitore", value: "fornBudgetDTO.selezioneImpegno" },
    { text: "Importo", value: "nImportoTotale" },
    { text: "Stato buono", value: "buonoStatoDTO.cDescrizione" },
    { text: "Impegno", value: "fornBudgetDTO.numeroDescrizione" },
    { text: "Anno impegno", value: "fornBudgetDTO.nAnno" },
  ];

  oggettoText =
    "Si  autorizza il prev. 2848 " +
    " " +
    "Nuovo Circondario Imolese " +
    "Servizio ECONOMATO ASSOCIATO " +
    " Nosco Gabriele  " +
    " \n \n" +
    "Si allega Buono n. 115157 del 09-09-2021 (Carrozzeria) " +
    " " +
    " IMPORTANTE: " +
    "In ottemperanza al DM.55/2013, dovrà essere emessa fattura elettronica , secondo formato di legge .Nella fattura, tra i vari dati obbligatori che dovranno comparire indicare anche i seguenti. " +
    "> Codice IPA del Servizio Economato Associato (come sopra indicato) " +
    "> Partita Iva (vedi logo) " +
    "> Codice Identificativo Gara: CIG (come sopra indicato) " +
    "> Riferimento amministrativo Impegno di spesa e determina (come sopra indicato) " +
    "> Scissione dei pagamenti ai sensi dell'Art. 17 ter DPR 633/1972  (Codice campo: ESESIGIBILITA' IVA: S) " +
    "> Numero identificativo di tutti i buoni a cui si riferisce la fattura. " +
    " " +
    "ALLEGARE ALLA FATTURA IL PRESENTE BUONO IN FORMATO PDF. " +
    " " +
    "L'ASSENZA ANCHE DI UN SOLO ELEMENTO DARA' LUOGO AL RISPINGIMENTO AUTOMATICO DELLA FATTURA";
  //t_documenti = ["Determina", "Protocollo", "Altro"];

  stampaPrezzi = [
    {
      id: "1",
      code: "N",
      cDescrizione: "Nessuno",
    },
    {
      id: "2",
      code: "S",
      cDescrizione: "Sintesi",
    },
    {
      id: "3",
      code: "D",
      cDescrizione: "Dettaglio",
    },
  ];

  options = {
      locale: "it-IT",
      prefix: "€",
      suffix: "",
      length: 11,
      precision: 2
    }

  eEnti = [];

  eUtente = null;

  eVeicoli = [];

  eAttrezzature = [];

  eFornitoriBudget = [];

  eScadenze = [];

  t_anni = [];

  t_causali = [];
  t_causaliOrig = [];

  t_statobuono = [];
  t_statobuonoOrig = [];

  t_buonotipo1 = [];
  t_buonotipo1Orig = [];

  t_buonotipo2 = [];
  t_buonotipo2Orig = [];

  t_buonotipo3 = [];
  t_buonotipo3Orig = [];

  t_interventi = [];
  t_interventiOrig = [];

  t_documenti = [];
  t_documentiOrig = [];

  // VARIABILI
  dimension = 0;
  defaultAnno = 0;
  tab = null;
  buonoTemplate = null;
  objEmissioneBuono = null;
  isEditEmissioneBuoni = false;
  isAlertDetailVisible = false;
  isAlertGridVisible = false;
  isBuonoStorico = false;
  isloading_buoni = false;
  isNuovoBuonoAttrez = false;
  isNuovoBuonoVeic = false;
  shouldDisable = true;
  enteShouldDisable = true;
  isHidden = false;
  isManodopDisabled = true;
  saveClicked = false;
  isScadSelected = false;
  isNuovoVeicEnabled = false;
  isNuovaAttrezEnabled = false;
  dialogDisattivaBuono = false;
  dialogChiudiBuono = false;

  selectedScad = [];
  tipoBuono = "T";
  messaggioAlert = "";
  alertType = "error";
  search = "";
  dialogEmettiBuono = false;
  prezzoSel = null;
  radioGroup = "2";
  objModalInvio = "Mail";
  isEmettiPossible = true;
  isCreatedCorrectly = false;

  headersScadenze = [
    {
      text: "Tipo scadenza",
      align: "start",
      value: "scadenzaTipo.cDescrizione",
    },
    { text: "Tipo intervento", value: "interventoTipo.cDescrizione" },
    { text: "Data decorrenza", value: "dataDecorrenzaText" },
    { text: "Data scadenza", value: "dataCorrenteText" },
    { text: "Buono", value: "isBuonoSel", sortable: false },
  ];

  mounted() {
    if (this.isCreatedCorrectly)
      this.onContainerChange();
  }

  created() {
    let verLoadingPage = Utils.verifyLoadingPage(this.$store, "R_BUONI");
    switch (verLoadingPage)
    {
      case 0: // All OK
        this.isCreatedCorrectly = true
        var storico = false;
        var enteId = this.$store.state.enteSel.id;
        // Carico Utenti ed Enti
        this.loadUser();
        this.loadEnti();
        // Caricamento Tipologiche
        this.loadAllTipologiche();
        //
        this.isNuovoVeicEnabled = Utils.isPageEnabled(this.$store, "W_BUONI_VEICOLI");
        this.isNuovaAttrezEnabled = Utils.isPageEnabled(this.$store, "W_BUONI_ATTREZZ");
        this.tipoBuono = 'T';
        if (this.isNuovoVeicEnabled && this.isNuovaAttrezEnabled)
          this.tipoBuono = 'T';
        else if (this.isNuovoVeicEnabled && !this.isNuovaAttrezEnabled)
          this.tipoBuono = 'V';
        else if (!this.isNuovoVeicEnabled && this.isNuovaAttrezEnabled)
          this.tipoBuono = 'A';
        // Carico i Veicoli e le Attrezzature dell'ente
        this.loadVeicoli(enteId, false);
        this.loadAttrezzature(enteId, false);
        // Carico i buoni del primo anno disponibile per l'ente selezionato
        this.loadAnni(enteId, storico);
        // Caricamento dei template
        this.getTemplateBuono();
        this.prezzoSel = this.stampaPrezzi[0];
        break;
      
      case 1: // Utente non loggato
        AuthService.logout();
        break;

      case 2: // Ente non selezionato
        this.$router.push({ name: "Dashboard" });
        break;

      case 3: // Permesso alla pagina non presente
        this.$router.push({ name: "Dashboard" });
    }
  }

  onTableRowClick(buono, row) {
    this.buoni.selected = buono;
    this.eScadenze = [];
    this.loadBuono(this.buoni.selected.id, this.onContainerChange);

    console.log("Buono selezionato: ");
    console.log(this.buoni.selected);
    if (!this.isEditEmissioneBuoni)
      this.tab = "Testa";

    this.isEditEmissioneBuoni = true;
    this.isNuovoBuonoVeic = false;
    this.isNuovoBuonoAttrez = false;

    this.onTableClickSetting();
    //this.fillTipologicheERelazioni();

    this.onContainerChange();

    this.isEmettiPossible = this.buoni.selected.buonoStatoDTO.cDescrizione != "Chiuso";
    this.isBuonoStorico = this.buoni.selected.buonoStatoDTO.cDescrizione == "Chiuso";
    this.shouldDisable = true;
    this.isManodopDisabled = true;
    this.isHidden = false;
  }

  onKeyDown(key) {
    if (key.key == "." || key.key == "," || key.key == "-")
    {
      key.preventDefault();
    }
    else
      return true;
  }

  onCloseDetail() {
    this.isEditEmissioneBuoni = false;
    this.isEmettiPossible = false;
    this.onContainerChange();
    this.shouldDisable = true;
    this.isHidden = false;
  }

  onContainerChange() {
    let self = this;
    // console.log("onContainerChange");
    self.$nextTick(function () {
      self.dimension = document.getElementById("tblMainBuoni").offsetWidth;
      return self.dimension;
    });
  }

  onAnnoChange() {
    var enteId = this.$store.state.enteSel.id;
    
    this.loadBuoni(this.defaultAnno, enteId);
    this.onCloseDetail();
  }

  onEditClick() {
    // Caricamento oggetti principali in relazione
    //this.loadEnti();
    // Tipologiche
    this.loadAllTipologicheAttive(true);

    this.loadFornitori(
      this.buoni.selected.enteDTO.id,
      this.buoni.selected.fornBudgetDTO.nAnno,
      this.buoni.selected.tipologiaBuono
    );
    //this.loadEnteAndLinkObjects(idEnte);

    // Carico da db l'oggetto Veicolo selezionato
    this.loadBuono(this.buoni.selected.id, this.onContainerChange);
    
    this.isEmettiPossible = false;
    this.shouldDisable = false;
    this.isManodopDisabled = this.buoni.selected.flagSoloRicambi;
    //this.onVeicoloChanged(this.buoni.selected.veicoloDTO);
  }

  onSaveClick() {
    // Verifico se i campi della form sono tutti validi.
    // Per funzionare nel template ci deve essere un oggetto di tipo V-FORM
    this.saveClicked = true;
    var formIsValid = this.$refs.form.validate();
    if (!formIsValid) {
      this.alertType = "error";
      // In caso di errore del salvataggio continuare con l'editing
      this.messaggioAlert = i18n.t("erroreCampiObbligatori");
      this.showHide_alertDetail(8000);
    } else {
      if (this.buoni.selected.id > 0)
        // Salvo il dato sul DB
        this.saveBuonoDto(this.buoni.selected.id);
      // Creo il nuovo record
      else this.createBuonoDto();
    }
    this.isManodopDisabled = true;
  }

  onEmettiClick() {
    // Salvo i dati del dialog Box
    if (!Utils.isNullOrUndefinedOrEmpty(this.objModalInvio))
      if (this.objModalInvio == "Mail")
        this.objEmissioneBuono.flagInvioEmail = true;
      else 
        this.objEmissioneBuono.flagInvioEmail = false;
    else this.objEmissioneBuono.flagInvioEmail = false;

    if (!Utils.isNullOrUndefinedOrEmpty(this.radioGroup))
      if (this.radioGroup == "1")
        this.objEmissioneBuono.flagManutenzione = true;
      else this.objEmissioneBuono.flagManutenzione = false;
    else this.objEmissioneBuono.flagManutenzione = false;
    this.objEmissioneBuono.cTipoStampa = this.buoni.selected.stampaPrezzi;
    this.objEmissioneBuono.eBuoniId = this.buoni.selected.id;
    this.dialogEmettiBuono = false;

    // Salvo l'emissione dell buono
    this.emettiBuonoDto(this.buoni.selected.id);
  }

  onBuoniApertiClick() {
    var buonoId = this.buoni.selected.id;
    if (this.buoni.selected.id == 0)
    {
      this.alertType = "error";
      this.messaggioAlert = i18n.t("erroreGeneraReportBuono");
      this.showHide_alertDetail(8000);
      return;
    }
    
    ExcelService.generaExcelBuoniAperti(
      buonoId,
      function(data, resp) {
          const blob = new Blob([data]);
          const el = document.createElement("a");
          el.href = window.URL.createObjectURL(blob);
          const regex = /filename=(?<filename>[^,;]+);/g;
          const match = regex.exec(resp.headers['content-disposition']);
          let filename = ""; // or any other value you consider default
          if (typeof match !== 'undefined' && match !== null) {
              filename = match.groups['filename'];
          }
          el.download = filename;
          el.click();
        },
        (fail) => {
          alert(fail);
        },
    )
  }

  onBudgetClick() {
    if (this.buoni.selected.id == 0)
    {
      this.alertType = "error";
      this.messaggioAlert = i18n.t("erroreGeneraReportBuono");
      this.showHide_alertDetail(8000);
      return;
    }
    var url = environment.urlSrc + "Buoni/GeneraBudgetFornitore";
    window.location = url + "?id_buono=" + this.buoni.selected.id;
  }

  onInterventoFornitoreClick() {
    if (this.buoni.selected.id == 0)
    {
      this.alertType = "error";
      this.messaggioAlert = i18n.t("erroreGeneraReportBuono");
      this.showHide_alertDetail(8000);
      return;
    }

    var url = environment.urlSrc + "Buoni/GeneraListaInterventiManutenzione";
    window.location =
      url + "?id_buono=" + this.buoni.selected.id + "&tipoIntervento=0";
  }

  onNuovoBuonoAttrezzatura() {
    // Chiudo l'oggetto precedente prima di aprire il nuovo
    this.$refs.form.resetValidation();
    this.saveClicked = false;
    this.isEmettiPossible = false;
    this.isBuonoStorico = false;
    this.isManodopDisabled = false;

    // Caricamento oggetti principali in relazione
    //this.loadEnti();
    // Tipologiche
    this.loadAllTipologicheAttive(true);

    //this.loadEnteAndLinkObjects(idEnte);

    // Impostazione variabili
    this.isEditEmissioneBuoni = true;
    this.shouldDisable = false;
    this.isHidden = true;
    // Svuoto oggetti collegati
    //this.eEnti = [];
    this.eVeicoli = [];
    this.eAttrezzature = [];
    this.eFornitoriBudget = [];
    this.eScadenze = [];

    this.onContainerChange();

    this.buoni.selected = Object.assign({}, this.buonoTemplate);
    this.buoni.selected.tipologiaBuono = "Attrezzatura";
    this.buoni.selected.interventi = [];

    this.tab = "Testa";
    if (Utils.isNullOrUndefinedOrEmpty(this.buoni.selected.enteDTO) || this.buoni.selected.enteDTO.id == -1)
      this.buoni.selected.enteDTO = this.$store.state.enteSel;
      //this.buoni.selected.enteDTO = [];
    
    this.loadAttrezzature(this.$store.state.enteSel.id, false);
    //if (Utils.isNullOrUndefinedOrEmpty(this.buoni.selected.attrezDTO)) this.buoni.selected.attrezDTO = [];
    if (Utils.isNullOrUndefinedOrEmpty(this.buoni.selected.veicoloDTO))
      this.buoni.selected.veicoloDTO = [];
      
    this.buoni.selected.nAliquota = 22;
  }

  onNuovoBuonoVeicolo() {
    // Chiudo l'oggetto precedente prima di aprire il nuovo
    this.$refs.form.resetValidation();
    this.saveClicked = false;
    this.isEmettiPossible = false;
    this.isBuonoStorico = false;
    this.isManodopDisabled = false;

    // Caricamento oggetti principali in relazione
    //this.loadEnti();
    // Tipologiche
    this.loadAllTipologicheAttive(true);

    //this.loadEnteAndLinkObjects(idEnte);

    // Impostazione variabili
    this.isEditEmissioneBuoni = true;
    this.shouldDisable = false;
    this.isHidden = true;
    // Svuoto oggetti collegati
    //this.eEnti = [];
    this.eVeicoli = [];
    this.eAttrezzature = [];
    this.eFornitoriBudget = [];
    this.eScadenze = [];

    this.onContainerChange();

    this.buoni.selected = Object.assign({}, this.buonoTemplate);
    this.buoni.selected.tipologiaBuono = "Veicolo";
    this.buoni.selected.interventi = [];
    this.tab = "Testa";
    if (Utils.isNullOrUndefinedOrEmpty(this.buoni.selected.enteDTO) || this.buoni.selected.enteDTO.id == -1)
      this.buoni.selected.enteDTO = this.$store.state.enteSel;
    
    this.loadVeicoli(this.$store.state.enteSel.id, false);

    this.buoni.selected.nAliquota = 22;
    /*
    if (Utils.isNullOrUndefinedOrEmpty(this.buoni.selected.veicoloDTO))
      this.buoni.selected.veicoloDTO = [];
    */
  }

  onEnteChanged(item) {
    this.loadVeicoli(this.buoni.selected.enteDTO.id, false);
    if (!Utils.isNullOrUndefinedOrEmpty(this.buoni.selected.dataBuonoText)) {
      this.loadFornitori(
        this.buoni.selected.enteDTO.id,
        this.buoni.selected.fornBudgetDTO.nAnno,
        this.buoni.selected.tipologiaBuono
      );
    }
  }

  onDataBuonoChanged(item) {
    if (
      !Utils.isNullOrUndefinedOrEmpty(this.buoni.selected.dataBuonoText) &&
      !Utils.isNullOrUndefinedOrEmpty(this.buoni.selected.enteDTO)
    ) {
      // Setto il campo calcolato
      this.buoni.selected.fornBudgetDTO.nAnno = UtilsDate.getYearFromDate(
        this.buoni.selected.dataBuonoText
      );
      // Ricalcolo i fornitori in base al nuovo anno
      this.loadFornitori(
        this.buoni.selected.enteDTO.id,
        this.buoni.selected.fornBudgetDTO.nAnno,
        this.buoni.selected.tipologiaBuono
      );
    }
  }

  onVeicoloChanged(veicolo) {
    this.eScadenze = [];
    this.selectedScad = [];
    for (let i = 0; i < this.buoni.selected.veicoloDTO.scadenze.length; i++) {
      var item = this.buoni.selected.veicoloDTO.scadenze[i];
      if (Utils.isNullOrUndefinedOrEmpty(item.eBuonoCorId)) {
        item.isBuonoSel = false;
        this.eScadenze.push(item);
      } else {
        if (item.eBuonoCorId == this.buoni.selected.id) {
          item.isBuonoSel = true;
          this.eScadenze.push(item);
          this.selectedScad.push(item);
        }
      }
    }
  }

  onAttrezzaturaChanged(attrez)
  {
    this.eScadenze = [];
    this.selectedScad = [];
    if (!Utils.isNullOrUndefined(this.buoni.selected.attrezDTO.scadenze))
    {
      for (let i = 0; i < this.buoni.selected.attrezDTO.scadenze.length; i++) {
        var item = this.buoni.selected.attrezDTO.scadenze[i];
        if (Utils.isNullOrUndefinedOrEmpty(item.eBuonoCorId)) {
          item.isBuonoSel = false;
          this.eScadenze.push(item);
        } else {
          if (item.eBuonoCorId == this.buoni.selected.id) {
            item.isBuonoSel = true;
            this.eScadenze.push(item);
            this.selectedScad.push(item);
          }
        }
      }
    }
  }

  onScadenzaClick(scadenza, checkvalue) {
    if (!this.shouldDisable) {
      // Ciclo tutte le scadenze e trovo quella modificata
      for (let i = 0; i < this.eScadenze.length; i++) {
        var item = this.eScadenze[i];
        if (item.id == scadenza.id) {
          // Se la scadenza è selezionata aggiungo l'id del buono altrimenti lo tolgo
          if (checkvalue == true) {
            item.isBuonoSel = true;
            item.eBuonoCorId = this.buoni.selected.id;
          } else {
            item.eBuonoCorId = null;
            item.isBuonoSel = false;
          }
          break;
        }
      }
      if (this.buoni.selected.veicoloDTO.id != 0)
        this.buoni.selected.veicoloDTO.scadenze = this.eScadenze;
      else if (this.buoni.selected.attrezDTO.id != 0)
        this.buoni.selected.attrezDTO.scadenze = this.eScadenze;
    }
  }

  onFornitoreChanged(fornitore) {
  }

  onMettiInBozzaClick() {
    // Setto il nuovo stato
    for (let i = 0; i < this.t_statobuono.length; i++) {
      var item = this.t_statobuono[i];
      if (item.id == 1)
      {
        this.buoni.selected.buonoStatoDTO = item;
        break;
      }
    }
    
    if (this.buoni.selected.tipologiaBuono == "Veicolo") {
      this.buoni.selected.attrezDTO = null;
    }
    else if (this.buoni.selected.tipologiaBuono == "Attrezzatura") {
      this.buoni.selected.veicoloDTO = null;
    }
    this.saveBuonoDto(this.buoni.selected.id);
  }

  onChiudiBuonoConfirmedClick() {
      // Tipo 1
      if (this.buoni.selected.buonoTipo1DTO.cDescrizione == "Fattura") {
        if (
          Utils.isNullOrUndefinedOrEmpty(this.buoni.selected.nImporto1) ||
          Utils.isNullOrUndefinedOrEmpty(this.buoni.selected.dataFattura1Text) ||
          Utils.isNullOrUndefinedOrEmpty(this.buoni.selected.cNumFattura1)
        ) {
          this.alertType = "error";
          this.messaggioAlert = i18n.t("erroreInserimentoTipoX") + "1";
          this.showHide_alertDetail(8000);
          return;
        }
      }

      if (this.buoni.selected.buonoTipo2DTO.cDescrizione == "Fattura") {
        if (
          Utils.isNullOrUndefinedOrEmpty(this.buoni.selected.nImporto2) ||
          Utils.isNullOrUndefinedOrEmpty(this.buoni.selected.dataFattura2Text) ||
          Utils.isNullOrUndefinedOrEmpty(this.buoni.selected.cNumFattura2)
        ) {
          this.alertType = "error";
          this.messaggioAlert = i18n.t("erroreInserimentoTipoX") + "2";
          this.showHide_alertDetail(8000);
          return;
        }
      }

      if (this.buoni.selected.buonoTipo3DTO.cDescrizione == "Fattura") {
        if (
          Utils.isNullOrUndefinedOrEmpty(this.buoni.selected.nImporto3) ||
          Utils.isNullOrUndefinedOrEmpty(this.buoni.selected.dataFattura3Text) ||
          Utils.isNullOrUndefinedOrEmpty(this.buoni.selected.cNumFattura3)
        ) {
          this.alertType = "error";
          this.messaggioAlert = i18n.t("erroreInserimentoTipoX") + "3";
          this.showHide_alertDetail(8000);
          return;
        }
      }

    this.chiudiBuonoDto(this.buoni.selected.id);
  }

  onAnteprimaClick() {
    //var url = environment.urlSrc + "Buoni/GeneraBuono";
    //window.location = url + "?id_buono=" + this.buoni.selected.id + "&tipo_stampa=" + this.buoni.selected.stampaPrezzi;
    // TODO
    if (this.buoni.selected.id == 0)
    {
      this.alertType = "error";
      this.messaggioAlert = i18n.t("erroreGeneraReportBuono");
      this.showHide_alertDetail(8000);
      return;
    }
    else
      this.loadReport(this.buoni.selected.id, this.buoni.selected.stampaPrezzi);
  }

  onDisattivaClick() {
    this.dialogDisattivaBuono = true;
  }

  confirmDisattivaBuono() {
    this.disattivaBuonoDto(this.buoni.selected.id);
    this.dialogDisattivaBuono = false;
  }

  closeDisattivaBuono() {
    this.dialogDisattivaBuono = false;
  }

  onChiudiBuonoClick() {
    this.dialogChiudiBuono = true;
  }

  confirmChiudiBuono() {
    this.onChiudiBuonoConfirmedClick();
    this.dialogChiudiBuono = false;
  }

  closeChiudiBuono() {
    this.dialogChiudiBuono = false;
  }

  onTableClickSetting() {
    // Se l'oggetto selezionato è un'attrezzatura inizializzo il veicolo con il template
    // Veicoli
    if (Utils.isNullOrUndefinedOrEmpty(this.buoni.selected.veicoloDTO)) {
      this.buoni.selected.veicoloDTO = [this.buonoTemplate.veicoloDTO];
    } else {
      // Gestione scadenze
      if (this.buoni.selected.veicoloDTO.id > 0)
      {
        this.onVeicoloChanged(this.buoni.selected.veicoloDTO);
      }
    }
    // Se l'oggetto selezionato è un veicolo inizializzo l'attrezzatura con il template.
    // Attrez.
    if (Utils.isNullOrUndefinedOrEmpty(this.buoni.selected.attrezDTO)) {
      this.buoni.selected.attrezDTO = [this.buonoTemplate.attrezDTO];
    }
    else {
      // Gestione scadenze
      if (this.buoni.selected.attrezDTO.id > 0)
      {
        this.onAttrezzaturaChanged(this.buoni.selected.attrezDTO);
      }
    }

    this.loadDefaultEmailBuonoDTO(this.buoni.selected.id);
  }

  fillTipologicheERelazioni() {
    // Tipologiche
    //this.loadTipoInterventi();
    //this.t_statobuono = [this.buoni.selected.buonoStatoDTO];
    //this.t_buonotipo1 = [this.buoni.selected.buonoTipo1DTO];
    //this.t_buonotipo2 = [this.buoni.selected.buonoTipo2DTO];
    //this.t_buonotipo3 = [this.buoni.selected.buonoTipo3DTO];
    //this.t_causali = [this.buoni.selected.buonoCausaleDTO];
    // Tipologiche
    //this.loadAllTipologicheAttive(false);

    // Relazioni
    //this.eEnti = [this.buoni.selected.enteDTO];
    /*
    // Veicoli
    this.eVeicoli = [this.buoni.selected.veicoloDTO];
    if (Utils.isNullOrUndefinedOrEmpty(this.buoni.selected.veicoloDTO)) {
      this.buoni.selected.veicoloDTO = [this.buonoTemplate.veicoloDTO];
      this.eVeicoli = [this.buonoTemplate.veicoloDTO];
    } else {
      // Gestione scadenze
      if (this.buoni.selected.veicoloDTO.id > 0)
        this.onVeicoloChanged(this.buoni.selected.veicoloDTO);
    }
    // Attrez.
    this.eAttrezzature = [this.buoni.selected.attrezDTO];
    if (Utils.isNullOrUndefinedOrEmpty(this.buoni.selected.attrezDTO)) {
      this.buoni.selected.attrezDTO = [this.buonoTemplate.attrezDTO];
      this.eAttrezzature = [this.buonoTemplate.attrezDTO];
    }
    else {
      // Gestione scadenze
      if (this.buoni.selected.attrezDTO.id > 0)
        this.onAttrezzaturaChanged(this.buoni.selected.attrezDTO);
    }
    */

   
    // Veicoli
    if (Utils.isNullOrUndefinedOrEmpty(this.buoni.selected.veicoloDTO)) {
      this.buoni.selected.veicoloDTO = [this.buonoTemplate.veicoloDTO];
    } else {
      // Gestione scadenze
      if (this.buoni.selected.veicoloDTO.id > 0)
        this.onVeicoloChanged(this.buoni.selected.veicoloDTO);
    }
    // Attrez.
    if (Utils.isNullOrUndefinedOrEmpty(this.buoni.selected.attrezDTO)) {
      this.buoni.selected.attrezDTO = [this.buonoTemplate.attrezDTO];
    }
    else {
      // Gestione scadenze
      if (this.buoni.selected.attrezDTO.id > 0)
        this.onAttrezzaturaChanged(this.buoni.selected.attrezDTO);
    }
    
    //this.eFornitoriBudget = [this.buoni.selected.fornBudgetDTO];

/*
    // Creazione Guid oggetti in relazione
    for (let i = 0; i < this.buoni.selected.documenti.length; i++) {
      var item = this.buoni.selected.documenti[i];
      item.guid = Utils.createGuid();
      item.hIns = UtilsDate.convertStringToDate(item.hIns);
    }

    for (let i = 0; i < this.buoni.selected.interventi.length; i++) {
      var item = this.buoni.selected.interventi[i];
      item.guid = Utils.createGuid();
      item.hIns = UtilsDate.convertStringToDate(item.hIns);
    }
    */
    //
    this.loadDefaultEmailBuonoDTO(this.buoni.selected.id);
  }
/*
  get euro1Display() {
    if (Utils.isNullOrUndefinedOrEmpty( this.buoni.selected.nImporto1))
      return this.buoni.selected.nImporto1;
    else
      return this.buoni.selected.nImporto1.toFixed(2);
  }
  set euro1Display(newValue) {
    this.buoni.selected.nImporto1 = newValue;
  }
  */

  getTabSelected(tabId) {
    if (this.saveClicked) this.$refs.form.validate();
  }

  loadAllTipologiche() {
    // Caricamento di tutte le Tipologiche
    // Buoni
    this.loadTCausali();
    // Documenti
    this.loadTDocumenti();
    // Interventi
    this.loadTipoInterventi();
    // Tipo 1 / 2 / 3
    this.loadTBuoniTipo();
    // Stato
    this.loadTBuoniStato();
  }

  loadAllTipologicheAttive(soloAttive) {
    // Caricamento di tutte le Tipologiche
    if (soloAttive)
    {
      // SOLO le tipologiche attive
      this.t_causali = Utils.getTipologicheAttive(this.t_causaliOrig);
      this.t_statobuono = Utils.getTipologicheAttive(this.t_statobuonoOrig);
      this.t_buonotipo1 = Utils.getTipologicheAttive(this.t_buonotipo1Orig);
      this.t_buonotipo2 = Utils.getTipologicheAttive(this.t_buonotipo2Orig);
      this.t_buonotipo3 = Utils.getTipologicheAttive(this.t_buonotipo3Orig);
      this.t_interventi = Utils.getTipologicheAttive(this.t_interventiOrig);
      this.t_documenti = Utils.getTipologicheAttive(this.t_documentiOrig);
    }
    else
    {
      // TUTTE le tipologiche

      this.t_causali = this.t_causaliOrig;
      this.t_statobuono = this.t_statobuonoOrig;
      this.t_buonotipo1 = this.t_buonotipo1Orig;
      this.t_buonotipo2 = this.t_buonotipo2Orig;
      this.t_buonotipo3 = this.t_buonotipo3Orig;
      this.t_interventi = this.t_interventiOrig;
      this.t_documenti = this.t_documentiOrig;
    }
  }

  get headersColumns() {
    let tblWidth = this.dimension;
    //console.log(tblWidth);
    if (tblWidth < 1000) return this.headersColumnsRidotte;
    else return this.headersEmissioneBuoniMainColumns;
  }

  loadDocument(docTemp) {
    if (docTemp.id == 0) {
      // Aggiungo l'oggetto in griglia
      docTemp.guid = Utils.createGuid();
      if (this.buoni.selected.id > 0) docTemp.objectId = this.buoni.selected.id;
      this.buoni.selected.documenti.push(docTemp);
    } else {
      // Aggiorno l'oggetto in griglia
      for (let i = 0; i < this.buoni.selected.documenti.length; i++) {
        var item = this.buoni.selected.documenti[i];
        if (item.guid == docTemp.guid) {
          item = docTemp;
        }
      }
    }
  }

  deleteDocument(guid) {
    for (let i = 0; i < this.buoni.selected.documenti.length; i++) {
      var item = this.buoni.selected.documenti[i];
      if (item.guid == guid) {
        item.hDel = new Date();
        break;
      }
    }
  }

  loadIntervento(intTemp) {
    if (intTemp.id == 0) {
      // Aggiungo l'oggetto in griglia
      intTemp.guid = Utils.createGuid();
      if (this.buoni.selected.id > 0) intTemp.eBuoniId = this.buoni.selected.id;
      this.buoni.selected.interventi.push(intTemp);
    } else {
      // Aggiorno l'oggetto in griglia
      for (let i = 0; i < this.buoni.selected.interventi.length; i++) {
        var item = this.buoni.selected.interventi[i];
        if (item.guid == intTemp.guid) {
          item = intTemp;
          break;
        }
      }
    }

    this.changeImportoInterventi();
  }

  deleteIntervento(guid) {
    for (let i = 0; i < this.buoni.selected.interventi.length; i++) {
      var item = this.buoni.selected.interventi[i];
      if (item.guid == guid) {
        if (item.id == 0)
          this.buoni.selected.interventi.splice(i, 1);
        else
          item.hDel = new Date();
        break;
      }
    }
    this.changeImportoInterventi();
  }

  showAlertMessage(alertType, localVariableName) {
    this.alertType = alertType;
    this.messaggioAlert = i18n.t(localVariableName);
    this.showHide_alertDetail(8000);
  }

  showHide_alertDetail(millisecondInterval, event) {
    //console.log('Hide alert');
    this.isAlertDetailVisible = true;
    // `event` is the native DOM event
    window.setTimeout(() => {
      this.isAlertDetailVisible = false;
      //console.log("hide alert after " + millisecondInterval/1000 + " seconds");
    }, millisecondInterval);
  }

  showHide_alertGrid(millisecondInterval, event) {
    //console.log('Hide alert');
    this.isAlertGridVisible = true;
    // `event` is the native DOM event
    window.setTimeout(() => {
      this.isAlertGridVisible = false;
      //console.log("hide alert after " + millisecondInterval / 1000 + " seconds");
    }, millisecondInterval);
  }

  // ***********************************************************
  // FUNZIONI E PROCEDURE DI GESTIONE PAGINA
  // ***********************************************************

  changeImportoTotale() {
    let euro1 = Utils.isNullOrUndefinedOrEmpty(this.buoni.selected.nImporto1)
      ? 0
      : this.buoni.selected.nImporto1;
    let euro2 = Utils.isNullOrUndefinedOrEmpty(this.buoni.selected.nImporto2)
      ? 0
      : this.buoni.selected.nImporto2;
    let euro3 = Utils.isNullOrUndefinedOrEmpty(this.buoni.selected.nImporto3)
      ? 0
      : this.buoni.selected.nImporto3;

    this.buoni.selected.nImportoTotale =
      parseFloat(euro1) + parseFloat(euro2) + parseFloat(euro3);
    this.buoni.selected.nImportoTotale = this.buoni.selected.nImportoTotale.toFixed(2);

    // Ricalcolo l'imponibile ricambi
    this.changeImponibileRicambi();
  }

  changeImportoInterventi() {
    let tot = 0;
    for (let i = 0; i < this.buoni.selected.interventi.length; i++) {
      var item = this.buoni.selected.interventi[i];
      if (!Utils.isNullOrUndefinedOrEmpty(item.nImporto))
        tot += parseFloat(item.nImporto);
    }

    this.buoni.selected.nImportoInterventiTotale = parseFloat(tot);
    this.buoni.selected.nImportoInterventiTotale = this.buoni.selected.nImportoInterventiTotale.toFixed(2);
  }

  changeImponibileRicambi() {
    let impTotale = Utils.isNullOrUndefinedOrEmpty(
      this.buoni.selected.nImportoTotale
    )
      ? 0
      : this.buoni.selected.nImportoTotale;

    let aliquota = Utils.isNullOrUndefinedOrEmpty(this.buoni.selected.nAliquota)
      ? 0
      : this.buoni.selected.nAliquota;
debugger;
    if (parseInt(aliquota) != 0 && parseFloat(impTotale) != 0) {
      let imponibileRicCalc = 0;
      let aliquotaCalc = 0;
      if (this.buoni.selected.flagSoloRicambi)
      {
        imponibileRicCalc = ((parseFloat(impTotale) * 100) / (100 + parseInt(aliquota)));
        imponibileRicCalc = imponibileRicCalc.toFixed(2);
        aliquotaCalc = parseFloat(impTotale) - parseFloat(imponibileRicCalc);
        aliquotaCalc = aliquotaCalc.toFixed(2);
      }
      else
      {
        let impManod = Utils.isNullOrUndefinedOrEmpty(
          this.buoni.selected.nImpManodopera
        )
          ? 0
          : this.buoni.selected.nImpManodopera;        
        imponibileRicCalc = ((parseFloat(impTotale) * 100) / (100 + parseInt(aliquota))) - impManod;
        imponibileRicCalc = imponibileRicCalc.toFixed(2);
        let sumTmp = parseFloat(imponibileRicCalc) + parseFloat(impManod);
        aliquotaCalc = parseFloat(impTotale) - (parseFloat(sumTmp));
        aliquotaCalc = aliquotaCalc.toFixed(2);
      }
     

      this.buoni.selected.nImportoIva = aliquotaCalc;
      this.buoni.selected.nImpRicambi = imponibileRicCalc;

      //let aliquotaCalc = (parseFloat(impTotale) * parseInt(aliquota)) / 100;v
      //aliquotaCalc = ((parseFloat(impTotale) * 100) / (100 + parseInt(aliquota)));

      this.buoni.selected.nImportoIva = parseFloat(aliquotaCalc);
      

      /*
      if (this.buoni.selected.flagSoloRicambi)
        this.buoni.selected.nImpRicambi =
          parseFloat(impTotale) - parseFloat(aliquotaCalc);
      else {
        // Devo escludere anche il costo della manodopera
        let impManod = Utils.isNullOrUndefinedOrEmpty(
          this.buoni.selected.nImpManodopera
        )
          ? 0
          : this.buoni.selected.nImpManodopera;
        this.buoni.selected.nImpRicambi =
          parseFloat(impTotale) - parseFloat(aliquotaCalc) - parseFloat(impManod);
        this.buoni.selected.nImpRicambi = this.buoni.selected.nImpRicambi.toFixed(2);
      }
      */
    } else {
      this.buoni.selected.nImpRicambi = null;
      this.buoni.selected.nImportoIva = null;
    }
  }

  changeOre() {
    let impManod = Utils.isNullOrUndefinedOrEmpty(
      this.buoni.selected.nImpManodopera
    )
      ? 0
      : this.buoni.selected.nImpManodopera;
    let prezzoUnit = Utils.isNullOrUndefinedOrEmpty(
      this.buoni.selected.nPrezzoManodopera
    )
      ? 0
      : this.buoni.selected.nPrezzoManodopera;
    if (prezzoUnit > 0)
    {
      this.buoni.selected.nOreManodopera =
        parseFloat(impManod) / parseFloat(prezzoUnit);
      this.buoni.selected.nOreManodopera = this.buoni.selected.nOreManodopera.toFixed(2);
    }
    // Aggiorno l'imponibile ricambi
    this.changeImponibileRicambi();
  }

  checkSoloImpRicambi(event) {
    this.isManodopDisabled = this.buoni.selected.flagSoloRicambi;
    if (this.isManodopDisabled) {
      this.buoni.selected.nImpManodopera = null;
      this.buoni.selected.nPrezzoManodopera = null;
      this.buoni.selected.nOreManodopera = null;
    }

    // Aggiorno l'imponibile ricambi
    this.changeImponibileRicambi();
  }

  /// *******************************
  /// DATABASE
  /// *******************************

  loadAnni(enteId, storico) {
    var self = this;

    self.isloading_buoni = true;
    BuoniService.getAnniBuoni(
      enteId,
      (resp) => {
        self.isloading_buoni = false;
        console.log("getAAnniBuoni:");
        console.log(resp);
        self.t_anni = resp;
        if (self.t_anni.length > 0) {
          if (self.t_anni.indexOf(new Date().getFullYear()) > -1)
            self.defaultAnno = new Date().getFullYear();
          else self.defaultAnno = self.t_anni[self.t_anni.length-1];
          self.loadBuoni(self.defaultAnno, enteId);
        } else {
          self.loadBuoni(new Date().getFullYear(), enteId);
        }
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertGrid(8000);
        self.isloading_buoni = false;
      }
    );
  }

  loadBuoni(anno, enteId) {
    var self = this;
    self.isloading_buoni = true;

    BuoniService.getBuoni(
      anno,
      enteId,
      this.tipoBuono, 
      (resp) => {
        self.isloading_buoni = false;
        console.log("Buoni:");
        console.log(resp);

        self.buoni.source = resp;
      },
      (err) => {
        self.alertType = "error";
        self.messaggioAlert = i18n.t("erroreCaricamentoBuoni");
        self.showHide_alertGrid(8000);
        self.isloading_buoni = false;
      }
    );
  }

  loadBuono(id, onSuccess) {
    var self = this;

    BuoniService.getBuono(
      id,
      (resp) => {
        console.log("Buono:");
        console.log(resp);

        self.buoni.selected = resp;
        // Creo i GUID
        for (let i = 0; i < self.buoni.selected.documenti.length; i++) {
          var item = self.buoni.selected.documenti[i];
          item.guid = Utils.createGuid();
          item.hIns = UtilsDate.convertStringToDate(item.hIns);
        }
        for (let i = 0; i < self.buoni.selected.interventi.length; i++) {
          var item = self.buoni.selected.interventi[i];
          item.guid = Utils.createGuid();
          item.hIns = UtilsDate.convertStringToDate(item.hIns);
        }
        // Carico i fornitori legati al buono
        self.loadFornitori(self.buoni.selected.enteDTO.id, self.defaultAnno, self.buoni.selected.tipologiaBuono);
        // Assegnazione dei Template agli oggetti non utilizzati
        if (self.buoni.selected.tipologiaBuono == "Veicolo") {
          //self.loadVeicoli(self.buoni.selected.enteDTO.id, false);
          self.buoni.selected.attrezDTO = self.buonoTemplate.attrezDTO;
          // Setto le scadenze del veicolo
          self.eScadenze = self.buoni.selected.veicoloDTO.scadenze;
          for (let i = 0; i < self.buoni.selected.veicoloDTO.scadenze.length; i++) {
            var item = self.buoni.selected.veicoloDTO.scadenze[i];
            if (Utils.isNullOrUndefinedOrEmpty(item.eBuonoCorId)) {
              item.isBuonoSel = false;
            } 
            else {
              if (item.eBuonoCorId == self.buoni.selected.id) {
                item.isBuonoSel = true;
              }
            }
          }

        } else {
          //self.loadAttrezzature(self.buoni.selected.enteDTO.id, false);
          self.buoni.selected.veicoloDTO = self.buonoTemplate.veicoloDTO;
          // Setto le scadenze dell'attrezzatura
          self.eScadenze = self.buoni.selected.attrezDTO.scadenze;
        }



        if (typeof onSuccess === "function") onSuccess(resp);
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertGrid(8000);
        self.isloading_fornitori = false;
      }
    );
  }

  updateGridBuono(id, onSuccess) {
    var self = this;

    BuoniService.getBuono(
      id,
      (resp) => {
        console.log("Buono:");
        console.log(resp);

        var buonoTmp = resp;

        for (let i = 0; i < buonoTmp.documenti.length; i++) {
          var item = buonoTmp.documenti[i];
          item.guid = Utils.createGuid();
          item.hIns = UtilsDate.convertStringToDate(item.hIns);
        }

        for (let i = 0; i < buonoTmp.interventi.length; i++) {
          var item = buonoTmp.interventi[i];
          item.guid = Utils.createGuid();
          item.hIns = UtilsDate.convertStringToDate(item.hIns);
        }
        //
        if (buonoTmp.tipologiaBuono == "Veicolo") {
          self.loadVeicoli(buonoTmp.enteDTO.id, false);
          buonoTmp.attrezDTO = self.buonoTemplate.attrezDTO;
        } else {
          self.loadAttrezzature(buonoTmp.enteDTO.id, false);
          buonoTmp.veicoloDTO = self.buonoTemplate.veicoloDTO;
        }

        if (typeof onSuccess === "function") onSuccess(resp);
        // Aggiorno la griglia
        var found = false;
        for (let i = 0; i < self.buoni.source.length; i++) {
          var item = self.buoni.source[i];
          if (item.id == id) {
            found = true;
            self.buoni.source.splice(i, 1);
            self.buoni.source.push(buonoTmp);
            self.buoni.selected = buonoTmp;
            break;
          }
        }
        if (!found)
        {
          // Caso di nuovo oggetto
          self.buoni.source.push(buonoTmp);
          self.buoni.selected = buonoTmp;
        }
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertGrid(8000);
        self.isloading_fornitori = false;
      }
    );
  }

  loadDefaultEmailBuonoDTO(id, onSuccess) {
    var self = this;

    BuoniService.getDefaultEmailBuonoDTO(
      id,
      (resp) => {
        console.log("getDefaultEmailBuonoDTO:");
        console.log(resp);

        self.objEmissioneBuono = resp;

        //if (typeof onSuccess === "function") onSuccess(resp);
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertGrid(8000);
        self.isloading_fornitori = false;
      }
    );
  }

  getTemplateBuono() {
    var self = this;
    BuoniService.getTemplateBuono(
      (resp) => {
        console.log("getTemplateBuono:");
        console.log(resp);
        self.buonoTemplate = resp;
        // Inizializzazione variabili non settate
        self.buonoTemplate.id = 0;
        self.buonoTemplate.cSigla = "";
        self.buonoTemplate.utenteDTO = self.eUtente;
        if (Utils.isNullOrUndefined(self.buonoTemplate.fornBudgetDTO)) {
          self.buonoTemplate.fornBudgetDTO = [];
          self.buonoTemplate.fornBudgetDTO.nTotale = 0;
        }
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertDetail(8000);
      }
    );
  }

  loadReport(id_buono, tipo_stampa) {
    var self = this;
    BuoniService.generaBuono(
      id_buono,
      tipo_stampa,
      function (data, resp) {
        const blob = new Blob([data]);
        const el = document.createElement("a");
        el.href = window.URL.createObjectURL(blob);
        const regex = /filename=(?<filename>[^,;]+);/g;
        const match = regex.exec(resp.headers["content-disposition"]);
        let filename = ""; // or any other value you consider default
        if (typeof match !== "undefined" && match !== null) {
          filename = match.groups["filename"];
        }
        el.download = filename;
        el.click();
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertDetail(8000);
      }
    );
  }

  loadEnti() {
    var self = this;

    EntiService.getEnti(
      (resp) => {
        console.log("GetEnti");
        console.log(resp);

        self.eEnti = resp;

        // Gestione Utente / Profilazione
        if (self.$store.state.roleSel != null) {
          if (self.$store.state.roleSel.CCode == "ADM") {
            if (self.$store.state.enteSel.id == 0) {
              // Può selezionare
              // TODO
              // Disabilito le select degli enti
              self.enteShouldDisable = false;
            } else {
              // E' bloccato come un utente qualsiasi
              // Setto l'ente selezionato in Dashboard
              if (!Utils.isNullOrUndefined(self.buoni.selected)) {
                self.buoni.selected.enteDTO = self.$store.state.enteSel;
                // Disabilito le select degli enti
                self.enteShouldDisable = true;
              }
            }
          } else {
            if (!Utils.isNullOrUndefined(self.buoni.selected)) {
              self.buoni.selected.enteDTO = self.$store.state.enteSel;

              // Disabilito le select degli enti
              self.enteShouldDisable = true;
            }
            // Disabilito le select degli enti
            self.enteShouldDisable = true;
          }
        }
      },
      (err) => {
        console.log("Errore GetEnti");
        console.log(err);
      }
    );
  }

  loadUser() {
    var self = this;
    console.log("User id:");
    console.log(this.$store.state.user.id);
    UtentiService.getUser(
      this.$store.state.user.id,
      (resp) => {
        console.log("getUser");
        console.log(resp);

        self.eUtente = resp;
      },
      (err) => {
        console.log("Errore getUser");
        self.alertType = "error";
        self.messaggioAlert = i18n.t("erroreCaricamentoDatiUtente");
        self.showHide_alertGrid(8000);
      }
    );
  }

  loadVeicoli(enteId, storico) {
    var self = this;

    VeicoliService.getVeicoliBuoni(
      enteId,
      storico,
      (resp) => {
        console.log("Veicoli:");
        console.log(resp);

        self.eVeicoli = resp;
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = i18n.t("erroreCaricamentoVeicoli");
        self.showHide_alertGrid(8000);
      }
    );
  }

  loadAttrezzature(enteId, storico) {
    var self = this;
  
    AttrezzatureService.getAttrezzatureBuoni(
      enteId,
      storico,
      (resp) => {
        console.log("Attrezzature:");
        console.log(resp);

        self.eAttrezzature = resp;
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = i18n.t("getAttrezzatureBuoni");
        self.showHide_alertGrid(8000);
      }
    );
  }


  loadFornitori(enteId, anno, tipoForn) {
    var self = this;

    FornitoriService.getFornBudgetEnte(
      enteId,
      anno,
      tipoForn,
      (resp) => {
        console.log("getFornBudgetEnte:");
        console.log(resp);

        self.eFornitoriBudget = resp;
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = i18n.t("erroreCaricamentoFornitori");
        self.showHide_alertGrid(8000);
      }
    );
  }

  createBuonoDto() {
    var self = this;

    BuoniService.createBuono(
      self.buoni.selected,
      (resp) => {
        console.log("createBuono");
        console.log(resp);
        if (resp.result == 1) {
          // Buon fine
          self.alertType = "success";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertDetail(5000);
          // Aggiorno la griglia principale
          self.updateGridBuono(resp.idNewObject, null);
          self.isEmettiPossible = true;
          self.isHidden = !self.isHidden;
          self.shouldDisable = true;
        } else {
          // KO
          self.alertType = "error";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertDetail(8000);
        }

        console.log(resp.userMsg.message);
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertDetail(8000);
      }
    );
  }

  saveBuonoDto(id) {
    var self = this;

    BuoniService.updateBuono(
      self.buoni.selected,
      (resp) => {
        console.log("updateBuono");
        console.log(resp);

        if (resp.result == 1) {
          // Buon fine
          self.alertType = "success";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertDetail(5000);
          // Aggiorno la griglia principale
          self.updateGridBuono(self.buoni.selected.id, null);
          self.isEmettiPossible = true;
        } else {
          // KO
          self.alertType = "error";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertDetail(8000);
        }

        this.isHidden = !this.isHidden;
        this.shouldDisable = true;
        console.log(resp.userMsg.message);
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertDetail(8000);
      }
    );
  }

  emettiBuonoDto(id) {
    var self = this;
    BuoniService.emettiBuono(
      this.objEmissioneBuono,
      (resp) => {
        console.log("emettiBuonoDto");
        console.log(resp);

        if (resp.result == 1) {
          // Buon fine
          self.alertType = "success";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertDetail(5000);
          // Ricarico l'oggetto appena salvato ed aggiorno la griglia principale
          self.updateGridBuono(self.buoni.selected.id, null);
        } else {
          // KO
          self.alertType = "error";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertDetail(8000);
        }

        //this.isHidden = !this.isHidden;
        //this.shouldDisable = true;
        console.log(resp.userMsg.message);
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertDetail(8000);
      }
    );
  }

  chiudiBuonoDto(id) {
    var self = this;
    if (this.buoni.selected.tipologiaBuono == "Veicolo") {
      this.buoni.selected.attrezDTO = null;
    }
    else if (this.buoni.selected.tipologiaBuono == "Attrezzatura") {
      this.buoni.selected.veicoloDTO = null;
    }
    BuoniService.chiudiBuono(
      this.buoni.selected,
      (resp) => {
        console.log("chiudiBuonoDto");
        console.log(resp);

        if (resp.result == 1) {
          // Buon fine
          self.alertType = "success";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertDetail(5000);
          // Ricarico l'oggetto appena salvato ed aggiorno la griglia principale
          self.updateGridBuono(self.buoni.selected.id, null);
          self.isEmettiPossible = false;
          self.isBuonoStorico = true;
        } else {
          // KO
          self.alertType = "error";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertDetail(8000);
        }

        console.log(resp.userMsg.message);
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertDetail(8000);
      }
    );
  }

  disattivaBuonoDto(id) {
    var self = this;

    BuoniService.deleteBuono(
      self.buoni.selected.id,
      (resp) => {
        console.log("deleteBuono");
        console.log(resp);
        if (resp.result == 1) {
          // Buon fine
          self.alertType = "success";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertGrid(5000);
          
          // Ricarico l'oggetto appena salvato ed aggiorno la griglia principale
          self.onCloseDetail();
          var storico = false;
          var enteId = this.$store.state.enteSel.id;
          self.loadAnni(enteId, storico);
          //self.updateGridBuono(self.buoni.selected.id, null);
          self.isEmettiPossible = false;
        } else {
          // KO
          self.alertType = "error";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertDetail(8000);
        }

        self.isHidden = !self.isHidden;
        self.shouldDisable = true;
        console.log(resp.userMsg.message);
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertDetail(8000);
      }
    );
    
  }

  /// **********************
  /// TIPOLOGICHE
  /// **********************

  loadTipoInterventi() {
    var self = this;

    TipologicheService.getTInterventoTipo(
      (resp) => {
        console.log("getTInterventoTipo");
        console.log(resp);

        self.t_interventi = resp;
        self.t_interventiOrig = Utils.cloneArray(self.t_interventi);
      },
      (err) => {
        console.log("Errore getTInterventoTipo");
        console.log(err);
      }
    );
  }

  loadTDocumenti() {
    var self = this;

    TipologicheService.getTDocumentoBuoniAll(
      (resp) => {
        console.log("getTDocumentoBuoniAll");
        console.log(resp);

        self.t_documenti = resp;
        self.t_documentiOrig = Utils.cloneArray(self.t_documenti);
      },
      (err) => {
        console.log("Errore getTDocumentoBuoniAll");
        console.log(err);
      }
    );
  }

  loadTCausali() {
    var self = this;

    TipologicheService.getTBuoniCausali(
      (resp) => {
        console.log("getTBuoniCausali");
        console.log(resp);

        self.t_causali = resp;
        self.t_causaliOrig = Utils.cloneArray(self.t_causali);
      },
      (err) => {
        console.log("Errore getTBuoniCausali");
        console.log(err);
      }
    );
  }

  loadTBuoniTipo() {
    var self = this;

    TipologicheService.getTBuoniTipo(
      (resp) => {
        console.log("getTBuoniTipo");
        console.log(resp);

        self.t_buonotipo1 = resp;
        self.t_buonotipo2 = resp;
        self.t_buonotipo3 = resp;
        self.t_buonotipo1Orig = Utils.cloneArray(self.t_buonotipo1);
        self.t_buonotipo2Orig = Utils.cloneArray(self.t_buonotipo2);
        self.t_buonotipo3Orig = Utils.cloneArray(self.t_buonotipo3);
      },
      (err) => {
        console.log("Errore getTBuoniTipo");
        console.log(err);
      }
    );
  }

  loadTBuoniStato() {
    var self = this;

    TipologicheService.getTBuoniStato(
      (resp) => {
        console.log("getTBuoniTipo");
        console.log(resp);

        self.t_statobuono = resp;
        self.t_statobuonoOrig = Utils.cloneArray(self.t_statobuono);
      },
      (err) => {
        console.log("Errore getTBuoniTipo");
        console.log(err);
      }
    );
  }

  onExportGrid() {
    var enteId = this.$store.state.enteSel.id;
    var anno = this.defaultAnno;
    
    ExcelService.generaExcelBuoni(
      anno,
      enteId,
      function(data, resp) {
          const blob = new Blob([data]);
          const el = document.createElement("a");
          el.href = window.URL.createObjectURL(blob);
          const regex = /filename=(?<filename>[^,;]+);/g;
          const match = regex.exec(resp.headers['content-disposition']);
          let filename = ""; // or any other value you consider default
          if (typeof match !== 'undefined' && match !== null) {
              filename = match.groups['filename'];
          }
          el.download = filename;
          el.click();
        },
        (fail) => {
          alert(fail);
        },
    )
  }

}
</script>

<style>
</style>