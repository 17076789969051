import UtilsRequest from '@/utils/UtilsRequest';

const API_URL = 'Attrezzature/';

class AttrezzatureService {
    getTemplateAttrezzatura(successFn, failFn){
        UtilsRequest.get(API_URL + "GetTemplateAttrezzatura", null,
        successFn,
        failFn
        );
    }

    getAttrezzature(enteId, storico, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("enteId", enteId);
        parametri.append("storico", storico);
        UtilsRequest.get(API_URL + "GetAttrezzature", parametri, 
        successFn,
        failFn
        );
    }

    getAttrezzatureBuoni(enteId, storico, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("enteId", enteId);
        parametri.append("storico", storico);
        UtilsRequest.get(API_URL + "GetAttrezzatureBuoni", parametri, 
        successFn,
        failFn
        );
    }

    getAttrezzatura(id, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("id", id);
        UtilsRequest.get(API_URL + "GetAttrezzatura", parametri, 
        successFn,
        failFn
        );
    }

    createAttrezzatura(attrezzatura, successFn, failFn){
        UtilsRequest.post(API_URL + "CreateAttrezzatura", attrezzatura, 
        successFn,
        failFn
        );
    }

    updateAttrezzatura(attrezzatura, successFn, failFn){
        UtilsRequest.put(API_URL + "UpdateAttrezzatura", attrezzatura, 
        successFn,
        failFn
        );
    }

    deleteAttrezzatura(id, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("id", id);
        UtilsRequest.delete(API_URL + "DeleteAttrezzatura", parametri, 
        successFn,
        failFn
        );
    }
}

export default new AttrezzatureService();
