import Utils from '@/utils/Utils';
import UtilsRequest from '@/utils/UtilsRequest';

const API_URL = 'Documenti/';

class DocumentiService {
    getTemplateDocumento(successFn, failFn){
        UtilsRequest.get(API_URL + "GetTemplateDocumento", null,
        successFn,
        failFn
        );
    }

    getDocumentoFile(idDoc, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("id", idDoc);

        UtilsRequest.get(API_URL + "GetDocumentoFile", parametri, 
            successFn,
            failFn,
        undefined, "arraybuffer");
        }

}

export default new DocumentiService();